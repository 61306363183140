import pre_primary from "./image/pre-primary.jpg";
import primary from "./image/newImg/primary.jpg";
import secondary from "./image/secondary.jpg";
import sr_secondary from "./image/sr_secondary.jpg";

//alumni
import abhishek from "./image/abhishek.jpg";
import prakriti from "./image/prakriti.jpg";
import karandev from "./image/karandev.jpg";
import rohan from "./image/rohan.jpg";
import anjan from "./image/anjan.jpg";
import subham from "./image/subham.jpg";
import vivek from "./image/vivek.jpg";
import namrata from "./image/namrata.jpg";
import rakesh from "./image/rakesh.jpg";
import shwetank from "./image/shwetank.jpg";
import juli from "./image/juli.jpg";
import gaurav from "./image/gaurav.jpg";
import niketa from "./image/niketa.jpg";

//
import JinsiPhoto from "./image/newImg/Jinsi-picture.png";
import SumantaPhoto from "./image/newImg/Sumanta.jpg";
import DebarotiPhoto from "./image/debaroti.png";
import subrata from "./image/subrata.jpg";
import infrapara1 from "./image/infrapara1.jpg";

import slider_1 from "./image/slider_1.jpg";
import computer_lab from "./image/computer_lab.jpg";
import classroom from "./image/classroom.jpg";
import trippara2 from "./image/trippara2.jpeg";
import trippara3 from "./image/trip3.jpeg";
import trippara4 from "./image/trippara4.jpeg";
import digitallibrary from "./image/digitallibrary.jpeg";

//
import result_10 from "./image/result-10.jpg";
import result_10_1 from "./image/result-10-1.jpg";
import result_10_2 from "./image/result-10-2.jpg";
import result_10_3 from "./image/result-10-3.jpg";
import result_12_1 from "./image/result-12-1.jpg";
import result_12_2 from "./image/result-12-2.jpg";
import result_12_3 from "./image/result-12-3.jpg";

//event

import road_safety from "./image/road_safety.jpg";
import watermelon from "./image/watermelon.jpg";
import awareness from "./image/awareness.jpg";
import charcha from "./image/charcha.jpg";
import brambedkar from "./image/brambedkar.jpg";
//
import laboratories from "./image/laboratories.jpg";
import library from "./image/library.jpg";

import transportation from "./image/transportation.jpg";
//
import life_skill_1 from "./image/life_skill_1.jpg";
import life_skill_2 from "./image/life_skill_2.jpg";
import vocational_1 from "./image/vocational_1.jpg";
import vocational_2 from "./image/vocational_2.jpg";
import skill_based from "./image/skill_based.jpg";
import skill_based_2 from "./image/skill_based_2.jpg";
import annual_1 from "./image/annual_1.jpg";
import annual_2 from "./image/annual_2.jpg";
import annual_3 from "./image/annual_3.jpg";
import sports_club from "./image/sports_club.jpg";
import environment_club from "./image/environment_club.jpg";
import expression_club from "./image/expression_club.jpg";
import student_council_1 from "./image/student_council_1.jpg";
import student_council_2 from "./image/student_council_2.jpg";
import student_council_3 from "./image/student_council_3.jpg";
import ncc_1 from "./image/ncc_1.jpg";
import school_band_1 from "./image/school_band_1.jpg";
import scouts_1 from "./image/scouts_1.jpg";
import bus_1 from "./image/bus_1.jpg";
import award_1 from "./image/award_1.jpg";
import award_2 from "./image/award_2.jpg";
import award_3 from "./image/award_3.jpg";
import award_4 from "./image/award_4.jpg";
import award_5 from "./image/award_5.jpg";
import award_6 from "./image/award_6.jpg";
import award_7 from "./image/award_7.jpg";
import award_8 from "./image/award_8.jpg";
import award_9 from "./image/award_9.jpg";
import award_10 from "./image/award_10.jpg";
import pre_primary_1 from "./image/pre_primary_1.jpg";
import pre_primary_2 from "./image/pre_primary_2.jpg";
import primary_1 from "./image/primary_1.jpg";
import secondary_1 from "./image/secondary_1.jpg";
import secondary_2 from "./image/secondary_2.jpg";
import secondary2 from "./image/secondary2.jpg";
import sr_secondary_1_1 from "./image/newImg/sr_secondary_1_1.jpg";
import sr_secondary_2 from "./image/sr_secondary_2.jpg";
import career_1 from "./image/career_1.jpg";
import career_2 from "./image/career_2.jpg";
import career_4 from "./image/career_4.jpg";
import career_5 from "./image/career_5.jpg";
import sports_1 from "./image/sports_1.jpg";
import sports_3 from "./image/sports_3.jpg";
import arts_club from "./image/arts_club.jpg";
import playground_1 from "./image/playground_1.jpg";
import vision from "./image/vision.jpg";
import mission from "./image/mission.jpg";
import antibullying from "./image/anti_bullying.jpg";
import health_1 from "./image/health_1.jpg";
import health_2 from "./image/health_2.jpg";
import value_club from "./image/value_club.jpg";
import social_impact from "./image/social_impact.jpg";
import gender_sensitivity from "./image/gender_sensitivity.jpg";
import english_club from "./image/english_club.jpg";
import budding_1 from "./image/budding_1.jpg";
import budding_2 from "./image/budding_2.jpg";
import sdmc from "./image/sdmc.jpg";
import sdmc_2 from "./image/sdmc_2.jpg";
import house_system from "./image/house_system.jpg";
import trip_1 from "./image/trip_1.jpg";
import trip_cover from "./image/trip_cover.jpg";
import trip_cover_1 from "./image/trip_cover_1.jpg";
import trip_4 from "./image/trip_4.jpg";
import trips_1 from "./image/trips_1.jpg";
import primary_curriculum from "./image/primary_curriculum.jpg";
import interactive from "./image/interactive.jpg";
import happy_classroom from "./image/happy_classroom.jpg";
import experimental_learning from "./image/experimental_learning.jpg";
import spacious_classroom from "./image/spacious_classroom.jpg";
import spacious_classroom_2 from "./image/spacious_classroom_2.jpg";
import stem_lab from "./image/stem_lab.jpg";
import stem_lab_2 from "./image/stem_lab_2.jpg";
import computer_lab_1 from "./image/computer_lab_1.jpg";
import computer_lab_2 from "./image/computer_lab_2.jpg";
import laboratories_2 from "./image/laboratories_2.jpg";
import home_science from "./image/home_science.jpg";
import home_science_2 from "./image/home_science_2.jpg";
import arts from "./image/arts.jpg";
import arts_2 from "./image/arts_2.jpg";
import library_2 from "./image/library_2.jpg";
import cultural from "./image/cultural.jpg";
import cultural_2 from "./image/cultural_2.jpg";
import cultural_3 from "./image/cultural_3.jpg";
import cultural_4 from "./image/cultural_4.jpg";
import bio_lab from "./image/bio_lab.jpg";
import chem_lab from "./image/chem_lab.jpg";
import phy_lab from "./image/phy_lab.jpg";
import infrastructure_1 from "./image/infrastructure.jpg";
import faculty_1 from "./image/newImg/1.png";
import faculty_2 from "./image/newImg/2.png";
import faculty_3 from "./image/newImg/3.png";
import faculty_4 from "./image/newImg/4.png";
import faculty_5 from "./image/newImg/5.png";
import faculty_6 from "./image/newImg/6.png";
import aa from "./image/newImg/aa.jpeg";
import a from "./image/newImg/a.jpg";
import b from "./image/newImg/b.jpg";
import c from "./image/newImg/c.jpg";
import d from "./image/newImg/d.jpg";
import e from "./image/newImg/e.jpg";
import biology_lab from "./image/biology_lab.jpg";
import chemistry_lab from "./image/chemistry_lab.jpg";
import physic_lab from "./image/physic_lab.jpg";

import gender_img from "./image/gender_img.jpg";
import expression_img from "./image/expression_img.jpg";

import pre1 from "./image/newImg/pre1.jpeg";
import pre2 from "./image/newImg/pre2.jpeg";

import red from "./image/newImg/red.jpeg";
import blue from "./image/newImg/blue.jpeg";
import yellow from "./image/newImg/yellow.jpeg";
import green from "./image/newImg/green.jpeg";

import expression from "./image/newImg/expression.png";
import ba from "./image/newImg/ba.png";

import media1 from "./image/newImg/media/2.jpg";
import media2 from "./image/newImg/media/4.jpg";
import media3 from "./image/newImg/media/5.jpg";
import media4 from "./image/newImg/media/6.jpg";
import media5 from "./image/newImg/media/7.jpg";
import media from "./image/newImg/media/8.jpg";
import media6 from "./image/newImg/media/9.jpg";
import media7 from "./image/newImg/media/10.jpg";
import media8 from "./image/newImg/media/11.jpg";
import media9 from "./image/newImg/media/12.jpg";
import media10 from "./image/newImg/media/13.jpg";
import media11 from "./image/newImg/media/1.jpg";
import media12 from "./image/newImg/media/3.jpg";

import sports_club_icon from "./image/clubs/sportsClubIcon.png";
import anti_bullying_icon from "./image/clubs/antiBullyingIcon.png";
import environment_club_icon from "./image/clubs/environmentClubIcon.png";
import health_club_icon from "./image/clubs/healthClubIcon.png";
import value_club_icon from "./image/clubs/valueClubIcon.png";
import social_impact_club_icon from "./image/clubs/socialImpactClub.png";
import gender_club from "./image/clubs/genderClub.png";
import english_club_icon from "./image/clubs/englishClub.png";
import gender from "./image/clubs/gender.jpg";

//
import orientation_1 from "./image/orientation_1.jpg";
import orientation_2 from "./image/orientation_2.jpg";
import orientation_3 from "./image/orientation_3.jpg";
import orientation_4 from "./image/orientation_4.jpg";
import book from "./image/book.jpg";
import waste from "./image/waste.jpg";
import waste_2 from "./image/waste_2.jpg";
import paper from "./image/paper.jpg";
import republic_1 from "./image/republic_1.jpg";
import republic_2 from "./image/republic_2.jpg";
import republic_3 from "./image/republic_3.jpg";
import covid from "./image/covid.jpg";
import mother from "./image/mother.jpg";
import first_aid from "./image/first_aid.jpg";
import basant_1 from "./image/basant_1.jpg";
import basant_2 from "./image/basant_2.jpg";
import pareeksha from "./image/pareeksha.jpg";
import pareeksha_2 from "./image/pareeksha_2.jpg";
import heritage from "./image/heritage.jpg";
import farewell from "./image/farewell.jpg";
import farewell_2 from "./image/farewell_2.jpg";
import environment from "./image/environment.jpg";
import fashion from "./image/fashion.jpg";
import fire from "./image/fire.jpg";
import donation_1 from "./image/donation_1.jpg";
import donation_2 from "./image/donation_2.jpg";

import { FaSchool } from "react-icons/fa";
//event
import christmas from "./image/event/christmas.jpg";
import dussehra from "./image/event/dussehra.jpg";
import earth from "./image/event/earth.jpg";
import gandhi from "./image/event/gandhi.jpg";
import grandparent from "./image/event/grandparent.jpg";
import hindi from "./image/event/hindi.jpg";
import idol from "./image/event/idol.jpg";
import interliteracy from "./image/event/interliteracy.jpg";
import intersport from "./image/event/intersport.jpg";
import news from "./image/event/news.jpg";
import openhouse from "./image/event/openhouse.jpg";
import poster from "./image/event/poster.jpg";
import ptm from "./image/event/ptm.jpg";
import rakhi from "./image/event/rakhi.jpg";
import safety from "./image/event/safety.jpg";
import skit from "./image/event/skit.jpg";
import storytelling from "./image/event/storytelling.jpg";

export const sidebar_data_vision = [
  {
    title: "Vision & Mission",
    navigation: "vision-and-mission",
  },
  {
    title: "Message From Leadership",
    navigation: "message",
  },
  {
    title: "Academics & Curriculum",
    navigation: "nep",
  },
  {
    title: "Beyond Curriculum",
    navigation: "house-clubs",
  },
  {
    title: "Sports & Fitness",
    navigation: "sports",
  },
  {
    title: "Faculty & Staff",
    navigation: "faculty-staff",
  },
  {
    title: "Events & Happenings",
    navigation: "events",
  },
  {
    title: "Parents' & Students' Voice",
    navigation: "parents",
  },
  {
    title: "Infrastructure & Facilities",
    navigation: "infrastructure",
  },
  {
    title: "Achievements and Laurels",
    navigation: "awards",
  },
  {
    title: "News & Media Coverage",
    navigation: "news",
  },
  {
    title: "Admissions",
    navigation: "admissions",
  },
];
export const academics_sidenav = [
  {
    title: "NEP 2020 Ready School ",
    navigation: "nep",
  },
  {
    title: "Curriculum ",
    navigation: "curriculum",
  },
  {
    title: "Pedagogical Approach",
    navigation: "pedagogi",
  },
  {
    title: "Life Skill Curriculum",
    navigation: "life-skill",
  },
  {
    title: "Assessment",
    navigation: "examination",
  },
  {
    title: "Skill & Vocational Subjects",
    navigation: "skill-vocational",
  },
  {
    title: "Results",
    navigation: "results",
  },
  {
    title: "Booklist",
    navigation: "books",
  },
  {
    title: "News Letter",
    navigation: "newsletter",
  },
  {
    title: "Institutional Planner",
    navigation: "planner",
  },
];
export const beyond_sidenav = [
  {
    title: "Houses & Clubs",
    navigation: "house-clubs",
  },
  {
    title: "Student Council",
    navigation: "student-council",
  },
  {
    title: "Trips, Excursions & Field Visits",
    navigation: "trips",
  },
  {
    title: "NCC, Band, Scouts & Guides",
    navigation: "ncc-scouts",
  },
];
//
export const vision_mission = [
  {
    id: 1,
    title: "Vision",
    description:
      "We at Hermann Gmeiner School believe in scholarly and accomplished learning. To provide all our students with advanced and qualified academic education along with the knowledge and experience in sports and co-curricular activities has always been our priority. Blending leisure with technology and creating a holistic experience for the educators to have the finest learning experience has been the precedence of our institution. We tend to upskill and upgrade all the children at the primary age with the will and spirit to be the best and brightest version of themselves. This helps them to grow and develop individually and in the society to become a good and contributing citizen of our country. ",
    description_image: [vision],
  },
  {
    id: 2,
    title: "Mission",
    description:
      "The mission of Hermann Gmeiner School is to create a safe space that nurtures the cerebral, social, emotional, physical, spiritual and academic development of every child. Our school strives to encourage and develop a sense of confidence and self-assurance in every juvenile. With a wide yet safe environment along with empathetic and patient staff, we want to empower our students and help them rise at every step of learning. By inculcating family and society values along with practical and theoretical knowledge, the children would learn the significance of selfhood and brotherhood.",
    description_image: [mission],
  },
];
export const message_data = [
  {
    id: 1,
    title: "President",
    occ: "SOS Children’s Villages of India",
    name: "Rakesh Jinsi",
    image: JinsiPhoto,
    description:
      "Rakesh Jinsi, an engineer by degree, has 41 years of experience in leadership roles at several institutions, including SOS Children's Villages. Presently running Khushboo, a daycare centre for children with severe mental and multiple disabilities, he has always been active in national level bodies such  as the National Human Rights Commission and Voluntary Action Network of India (VANI), the Apex body of the NGO sector.",
    extra_description: [
      `"I firmly believe that each and every child deserves an education that is advanced, detail-oriented yet nurturing and holistic. As President of SOS Children’s Villages of India, I wish to provide the best possible learning experience to the future leaders of our country, so that all children are able to reach their maximum potential and achieve their dreams. Students end up spending most of their childhood within classrooms, so it is essential that within those walls they are imparted proper theoretical knowledge, practical aptitude, communal values, essential life skills, and an understanding of how the world works and their place in it. ,
      "Adequate grooming and learning of children at a young age leads to them being strong, confident, hard-working and intelligent individuals, attributes that I deem necessary for the new era of technology and scientific progress we are heading towards."`,
    ],
  },
  {
    id: 2,
    title: "Secretary General",
    occ: "SOS Children’s Villages of India",
    name: "Sumanta Kar",
    image: SumantaPhoto,
    description:
      "Education at SOS Hermann Gmeiner Schools has always meant the Holistic Development of a student – we believe that each child is entitled to the best possible skill set for their future, therefore, each child entrusted with us will receive an education that is in its finest form. ",
    extra_description: [
      "Education is preparation for life, it should be an enjoyable, interactive and ongoing process to make the students confident young adults, sensitive to global issues, committed citizens who are aware of the happenings of the world and sensible individuals with a positive attitude. ",
      "Students should have a strong character and a good understanding of their humane and emotional sides. All our Hermann Gmeiner Schools have been actively engaged in relating to, understanding and working with communities around our schools.",
      "Safeguarding of children remains a top priority and they are nurtured through a theme-based curriculum and actively engage in experiential learning. Our teachers and principals play a pivotal role in the development of each child. Our alumni are our brand ambassadors, who leave a lasting footprint in whatever profession they choose to pursue. Each student who joins us becomes a future world leader, ready to bring progressive change and make our nation proud.",
    ],
  },
  {
    id: 3,
    title: "Head- Education & Child Development",
    occ: "SOS Children’s Villages of India",
    name: "Debaroti Bose",
    image: DebarotiPhoto,
    description:
      "I believe that school should be the experience of a lifetime. Our school experience sets the stage for success later in life and provides a lifetime of positive memories for our students. We are proud of our continuous goal of providing an excellent education for our students.We have a dynamic, hardworking faculty who are involved in education to benefit the students.The entire gamut of school education is transforming the teaching-learning process. ",
    extra_description: [
      "In the modern concept of schooling, teaching has become redundant, paving the way for the facilitation of learning through knowledge sharing. The wave of digital learning and cloud computing is reaching new heights.",
      " In all this whirlwind of new-age technology, it is important to charge ahead but with one’s head firmly on one’s shoulders. Hermann Gmeiner School strives to inculcate moral values, manners, responsibilities among students so that they may build a world free of all the evils.",
      "Students here are encouraged to dream, groomed to give wings to their dreams, to take off and explore the unexplored. The plethora of interesting & exciting opportunities available to youngsters today often leave them with an array of things to do.",
      " It is important therefore to help them build their focus on the subject that draws them most and gives shape to their vision. A student is made to blossom intellectually, morally, and spiritually. As Swami Vivekananda said, “Take up one idea. Make that one idea your life – think of it, dream of it, and live on that idea. Let the brain, muscles, nerves, every part of your body, be full of that idea, and just leave every other idea alone. This is the way to success.”",
    ],
  },
  {
    id: 4,
    title: "Principal",
    occ: "Hermann Gmeiner School, Bhopal",
    name: "Sunita Singh",
    image: subrata,
    description:
      "M.A., M.Ed., PG Diploma in Guidance and Counselling, International Diploma in Guidance and Counselling. 30 years of experience in the field of education as a Teacher, Administrator, Principal, Mentor and a Counsellor.Education itself is an inseparable part of living and we believe that each child is a gifted learner and he needs opportunities to learn and explore in a happy, healthy and safe environment. Today, the role of school is not only to work for academic excellence but also to empower the child to be a lifelong learner and critical thinker.",
    extra_description: [
      "We know that the ultimate goal of pre-primary education is to spark a pursuit of knowledge in the child so that they can keep on learning as they grow into whoever they choose to be. At this stage we prefer the child to be given opportunities to play and learn, learn and play through the various activities and exposure. In the same way the role of primary education is to ensure broad based learning of the child which includes development of social, cognitive, cultural, emotional and physical skills.",
      "We know that the pre-primary and primary education are the key foundation of lifelong education and in Hermann Gmeiner School, Bhopal, through experiential learning, thematic curriculum, value based morning assembly, yoga and sports, we strive to offer a plethora of opportunities to students to explore, experience, learn and find their strengths for strong foundations of further education.",
      "At the same time, our curriculum, teacher training, excellent student teacher ratio as per CBSE norms, which enable teachers to provide personal attention for each child so that he can connect with teachers and school to make learning more comfortable for him.",
      "Time flies on the wings of freedom. Hermann Gmeiner School, Bhopal has grown up into a wonderful, vibrant, safe and secure place of learning with experienced and qualified teachers.",
    ],
  },
];
export const infrastructure = [
  {
    id: 1,
    title: "Infrastructure",
    description: `
    Hermann Gmeiner School, Bhopal, was established in 1998, by the SOS Children’s Villages Organisation. The campus is located in a respected area of the city, BHEL, Piplani on Khajuri Kalan Road, Bhopal. With a sprawling campus area and a pollution free environment, we promise the well-being and protection of all the kids. The alma-mater and the grounds of Hermann Gmeiner School were all planned and designed by a German Architect and the unique structure of the building does not compromise with the classrooms or playgrounds of the students.
    `,
    description_image: [infrapara1],
  },
  {
    id: 6,
    title: "Spacious Classrooms",
    description: `The school boosts up spacious, well-ventilated and excellently furnished classrooms to make the students learning comfortable and enjoyable. The furniture were specially designed tables and chairs that could be very easily arranged as per required set up for group activities and boosting peer learning. In all classrooms display boards too are provided to display creative chart works, drawings and paintings and craft works.    `,
    description_image: [spacious_classroom],
  },
  {
    id: 6,
    title: "Stem Lab",
    description: `STEM education experiences are made available in a variety of settings by the school as a way of fostering a diverse STEM workforce. Educators are focused on improving Science and Mathematics instructions employing several approaches to K-12 STEM education. Students also were given opportunities to spend time with professionals in STEM fields.
      STEM is an abbreviation that stands for 4 closely related subjects that are Science, Technology, Engineering and Mathematics. These four theoretical and practical subjects are taught together under STEM education and starting from basics to giving precise knowledge of the subjects, professional guidelines are available at the school’s STEM lab. The students are taught by giving them real-life world problems and they are to solve these problems by theoretical and practical approach.
    `,
    description_image: [stem_lab],
  },
  {
    id: 2,
    title: "Computer Lab",
    description: `The school has a highly sophisticated state of the art computer lab. It is equipped with a computing facility and computers connected through the Local Area Network as well as Wi-Fi. The high-speed internet connectivity of 90+mbps (24*7) to access vast intellectual resources. These facilities are an integral part of the curriculum. Students are expected to use computers in their day-to-day study in order to meet the challenges of modern learning and to keep pace with global scenarios. Students are provided training on SAP, Tally ERP 9, MS Office-2016 and Internet. Institute website has been dynamically designed to update the latest development in the institute on a day-to-day basis.`,
    description_image: [computer_lab],
  },
  {
    id: 2,
    title: "Laboratories",
    description: `A scientific temperament is nurtured through fully equipped and suitably designed laboratories for Science. Students are encouraged to use the facilities for enhancing their innovative and scientific skills. It also provides an open environment for students to experiment and do research work. The division of the science department ensures that students do real experiments. Our science labs are not just a rich storehouse of costly equipment and the latest gadgets on display. They are workshops of practical experiments going far beyond the limits of prescribed syllabus. This is a place where the divine curiosity of students is at work.
    Our science labs are not just a rich storehouse of costly equipment and the latest gadgets on display. They are workshops of practical experiments going far beyond the limits of the prescribed syllabus. The teachers at the physics, chemistry, and biology labs challenge them with exciting projects, giving them a strenuous time clashing with known principles and existing data. This is a place where the divine curiosity of students is at work.
    `,
    description_image: [laboratories],
  },
  // {
  //   id: 3,
  //   title: "Biology Lab ",
  //   description: `Students are encouraged to learn through experiments and grasp diverse ideas beyond their textbooks in the Biology lab, which is separate from traditional classroom instruction. Experiments and observations make learning more engaging and enjoyable. Experiments are fun for students because they learn something new from the outcomes. Every student in grades I through XII has the opportunity to do the practical under the supervision of the professors.`,
  //   description_image:[biology_lab]
  // },
  // {
  //   id: 4,
  //   title: "Chemistry Lab ",
  //   description: `Hermann Gmeiner School has a fantastic Chemistry lab with all of the latest and best-in-class apparatus and chemicals, as well as a wonderful learning atmosphere where students do practicals under the supervision of the lecturers. All precautions are followed, and the youngsters learn the fundamentals of chemistry by doing their own experiments, observing, and drawing conclusions.
  //   `,
  //   description_image: [chemistry_lab],
  // },
  // {
  //   id: 5,
  //   title: "Physics Lab",
  //   description: `Physics lab of Hermann Gmeiner School has all the up-to-date equipment. This lab assists students in developing a broad range of basic experimental physics and data analysis skills and tools. It is also intended for each student to conduct experiments under the supervision of Physics lecturers. The whole operation of the Physics lab is conducted in accordance with established safety standards.`,
  //   description_image:[physic_lab]
  // },
  {
    id: 7,
    title: "Art & Craft Room (The Heart of Creativity)",
    description: `The Art Room fosters the creative and intellectual development of the students through quality visual arts programs, facilitated by the arts faculty. The art room drives each child to be curious and original in their work while they study a variety of Art forms such as 2D, 3D, and installations by exploring mediums like acrylic, charcoal, water, dry pastels, oil pastels, pencil coloring, clay, wires, papier mache, etc.
    The periods in the Art Room offer each child a learning experience that teaches him or her to be a risk-taker, thinker, inquirer, and a good communicator in a safe environment. The Art teachers help the students in drawing, painting, crafts work, best out of waste, and clay modeling. Providing the students with experiences in art, craft and design enables them to reflect critically on their own and others’ work. We aim that they learn to think and act as artists, working creatively and intelligently.
    `,
    description_image: [arts],
  },
  {
    id: 8,
    title: "Library",
    description: `The serene ambiance inspires a love for reading. Information at the click of a fingertip invites young users to delve into the magical world of words and the wealth of research material available. The school has a well-equipped library. The eclectic collection that export constitutes the Library currently includes over 10,000 books. The Library resources comprise Encyclopedias, Dictionaries on various subjects, general collections, valuable sourcebooks, critical works as well as specific skills-related books on Personality Development, Time Management, and Communication Skills.
    `,
    extra_description: [
      "The Library subscribes to research e-journals, besides subscribing to daily newspapers, magazines, and journals. The Digital library provides access to information not just to widen the horizons of the users' knowledge but also to support a host of their learning activities that range from book learning and newspaper reading to using e-journals and reading e-books.",
    ],
    description_image: [library],
  },
  {
    id: 8,
    title: "Digital Library",
    description: `Our School’ s Digital Library is a digital repository or digital collection of an online database of digital documents that can include Textbooks, Digital Documents, Question Banks, PDFs, Online Websites references, or other digital media formats. These digital information systems are able to exchange information with Students and Staff of the school and others through interoperability and sustainability.
    In addition to just storing content in digital libraries, it provides means for organising, searching, and retrieving the content contained in the digital collection. Digital Library transforms the school into an e -Learning campus where the school can allow the students to use the animated lessons in batches at their own ease and convenience. Our digital content is rich in animation with clear explanations.
    `,
    description_image: [digitallibrary],
  },
  {
    id: 3,
    title: "Playground",
    description: `Ever since the school has been established, it has only grown and paved its way to be one of the safest and premier educational institutions. To facilitate the students in all the activities, libraries, labs, grounds and auditoriums have been built. This would not only help them explore different areas of interest but also help them hone their skills
    Hermann Gmeiner School Bhopal has a big playground for all age groups students, play park for kindergarten students which provides an environment for children that facilitates play, typically outdoors. It consist of recreational equipment such as the seesaw, swing set, slide, jungle gym, many of which help children develop physical coordination, strength, and flexibility, as well as providing recreation and enjoyment and supporting social and emotional development.
    `,
    extra_description: [
      "Ever since the school has been established, it has only grown and paved its way to being one of the safest and premier educational institutions. To facilitate the students in all the activities, libraries, labs, grounds, and auditoriums have been built. This would not only help them explore different areas of interest but also help them hone their skills.",
    ],
    // features: [
    //   "Cognitive",
    //   "Physical",
    //   "Social"

    // ],
    // features_description:[
    //   "When kids interact with the sensory elements in the outdoors, they get the opportunity to problem-solve and think critically.",
    //   "School playgrounds promote health through climbing, jumping, and running while providing a controlled environment for physical activity.",
    //   "School playgrounds offer opportunities for kids to collaborate and engage with each other to overcome challenges."
    // ],

    description_image: [playground_1],
  },
  // {
  //   id: 11,
  //   title: "Transportation",
  //   description: `The school provides an optional facility for students to commute from home to school. This service is offered from various places of Bhopal to the school. The buses are operated by experienced & trained drivers/attendants to monitor the children’s safe journey. The school tries to provide safe and comfortable commuting for students from home to school and back. Our buses are specially equipped and fitted with speed governors, rear cameras, first-aid equipment, fire extinguishers, etc.`,
  //   description_image: [transportation],
  // },
];
export const career_guidance = [
  {
    id: 1,
    title: "Career Counselling Workshop",
    description: `The future depends on what you do today." Hermann Gmeiner School organised a Career Counseling and Guidance programme to direct the students to move on the right path. Parents from different fields and resource persons were invited to share their journey of success with students so that they could learn different aspects of success. The students engaged in the session, asked queries to have better vision for their future and were satisfied with the guidance.`,
    description_image: [career_1],
  },
  {
    id: 2,
    title: "",
    description: `Career building is a deep rooted process. It becomes tedious for adolescents to choose an appropriate career with great placements and a bright future. School plays an important role in this ever-growing process of finding oneself and establishing oneself.`,
    description_image: [career_2],
  },
  {
    id: 3,
    title: "",
    description: ` ITM Institute of Design & Media conducted a career counselling workshop on career opportunities in online world like “YOUTUBE, VFX, ANIMATION", etc. on Feb 16th ,2022. The key speaker was Ms. Jyotsna Raghunathan, who is a storyteller and a film maker with over 20 years of experience in writing scripts, directing films and working in the educational sector of media and mass communication. She has also specialized in curriculum design and development, as well as making films and writing scripts under her own company banner, Panvision. `,
    description_image: [career_4],
  },
  {
    id: 4,
    title: "",
    description: ` The workshop started with her addressing the issue of confusion being created in youngsters' mind while choosing a career. She talked about the varied courses available for all the streams and how these can be helpful in fulfilling their dreams. The students were attentive and interactive and asked queries like the exams to be taken up, combinations of subjects available, options offered, placements etc. It was an enriching session for the students of grade XII.`,
    description_image: [career_5],
  },
];
export const admissions = [
  {
    id: 1,
    title: "Admission Procedure",
    description: `Admission in Hermann Gmeiner Schools is made without any distinction of religion, race, caste, birth etc. In regard to reservation for students of BPL/EWS category it is governed by the RTE Act.`,
    features: [
      "Admission in classes Nursery to V will be done on a 'First - come First-served Basis' and after a entrance test.",
      "Admission in classes VI to VIII will be done on the basis of small tests for language, maths & Science concepts.",
      "For admission in class IX, the students from outside Hermann Gmeiner Schools have to appear in an Entrance Examination of 2:30 hrs. in the subjects of Hindi, English, Mathematics and General Science",
      "For admission in class XI, counselling will be conducted for Hermann Gmeiner School’s students and for other students’ entrance tests will be conducted in the subjects offered, subjected to the availability of vacancies",
      "Admission in classes X and XII is strictly governed by the norms of affiliating boards.",
      "Interview of Parents is mandatory. Interaction of the child with a specific class teacher is mandatory.",
      "The Principal's Admission committee's decision will be final and binding with regard to all admissions.",
      "Admission will be granted on the basis of vacancies available in different classes.",
      "Admission will be granted strictly on the basis of merit.",
    ],
  },
  {
    id: 4,
    title: "Admission Criteria",
    description: `CLASS NURSERY TO VIII`,
    features: [
      " Admission to Nursery: - Students must complete 3+ years of age as on 31st March of the academic year.",
      " Admission to Class-l: - Students must complete 6+ years of age as on 31st March, of the academic year.",
      " Admission to classes II-VIII: Report Card of the child has to be submitted with equivalent 60% in previous classes.",
      " T.C. to be collected from each and every child above Class-I and reason for shifting/transfer to be verified as per state education board norms.",
    ],
  },
  // {
  //   id: 2,
  //   title: "Age Criteria",
  //   features: [
  //     "LKG – A child completing 4 years of age as of 1st April of the Academic year.",
  //     "UKG - A child completing 5 years of age as of 1st April of the Academic year.",
  //     "Class 1 – A child must be 6 years of age as of 1st April of the Academic year.",
  //   ],
  // },
  // {
  //   id: 3,
  //   title: "Rejoining School after Absence",
  //   description: `For admission in a class, XI counseling will be conducted for Hermann Gmeiner School students, and other students' entrance tests will be conducted in the subject offered, subject to the availability of vacancies. Admissions to X and XII are strictly governed by CBSE norms. Admission to other classes shall purely depend on the vacancy and merit of the student. No claim for the seat can be made, whatsoever admission will be granted strictly on the merit of qualified candidates.
  //   `,
  //   features: [
  //     "A student who remains absent for more than 10 days without prior information may get his/her name struck off from school rolls.",
  //     "The student will only be permitted to attend classes following an absence from the school for any reason including illness if they bring an application from the parent /guardian or a proper medical certificate from the qualified doctor if under medical treatment.",
  //     "In all other cases, the child is readmitted with a payment of 50% of the admission fee.",
  //   ],
  // },
  // {
  //   id: 5,
  //   title: "Admission Committee",
  //   description: ``,
  //   features: [
  //     "Class Teacher (for interaction with the child)",
  //     "Written test as per grade level",
  //     "Headmistress / HeadMasters and Counsellor (First interaction of the parents and student).",
  //     " Final approval to be given by the Principal after interaction with the child and the parents with verification of all documents.",
  //     "Class VI onwards, final approval to be given by Head ECD.",
  //   ],
  // },
  // {
  //   id: 7,
  //   title: "Admission to Class XI",
  //   description: `The entrance test will be conducted in the basic subjects of Class X studied by the student (subject to the availability of vacancy).
  //   To be eligible for admission the child should get at least 65% in our school entrance exam. Students who qualified for their Class X from a recognized state board are eligible for the examination. However, it is mandatory to score the minimum percentage to be eligible for the stream chosen.
  //   `,
  // },
  // {
  //   title: `For admission to Science Stream with Mathematics`,
  //   features: [
  //     "A minimum of 65% in Mathematics Standard Paper (New Admissions)",
  //     "A minimum of 65% in Science (New Admissions)",
  //     "Aggregate marks in 5 subjects including Mathematics & Science must be 65% (New Admissions)",
  //     "For internal admissions, aggregate should be 60%.",
  //   ],
  // },
  // {
  //   title: `For admission to Science Stream without Mathematics`,
  //   features: [
  //     "A minimum of 65% in Science (New Admissions)",
  //     "Aggregate marks in any 5 five subjects including Mathematics & Science must be 65% (New Admissions)",
  //     "For internal admissions aggregate should be 60%",
  //   ],
  // },
  {
    id: 10,
    title: "Admission to Class IX",
    description: `For admission in class IX, the student must have finished his/her regular study in class VIII. The admission process will be conducted only for the students who are studying in any institution affiliated by state governments or UT governments or other certified boards. For admission in class IX the entrance exam will be of 2:30 hrs in the subjects of Hindi, English, Mathematics and General Science.`,
    extra_description: [
      `Admission to class XI :- Entrance Tests will be conducted in the subjects desired by the student, subject to the availability of vacancy. To be eligible for admission the child should get 60% In our school entrance exam. Students who qualified their Class X from a recognized state board are eligible for the examination. However, it is mandatory to score the minimum percentage to be eligible for the stream chosen.`,
    ],
  },
  // {
  //   id: 11,
  //   title: "Admission to Classes Nursery to VIII",
  //   description: ``,
  //   features: [
  //     `Admission to Nursery: Students must complete 3+ years of age as of 31st March of the academic year.`,
  //     `Admission to Class I: Students must complete 6+ years of age as of 31 March, of the academic year.`,
  //     `Admission to classes II-VIII: Report Card of the child has to be submitted with an equivalent of at least 60% in previous classes. TC to be collected from every child and the reason for shifting/transfer to be verified.`,
  //   ],
  // },
  {
    title: `For Commerce Stream`,
    features: [
      "A minimum of 55% aggregate marks in any 5 subjects",
      "Mathematics can be offered if the student has obtained a minimum of 60% marks in Mathematics standard paper",
    ],
  },
  {
    title: `For Humanities Stream`,
    features: [
      "A minimum of 55% aggregate marks in any 5 subjects ",
      "A minimum of 55% in Social Studies",
    ],
  },
  {
    title: `For admission to science stream with maths (Class X result)`,
    features: [
      "A minimum of 60% in Mathematics Standard Paper.",
      "A minimum of 60 % in Science.",
      "Aggregate marks in five subjects including Mathematics and Science must be 60%.",
    ],
  },
  {
    title: `For admission to science stream without Mathematics`,
    features: [
      "A minimum of 60% in Science.",
      "Aggregate marks in five subjects including Mathematics and Science must be 60%.",
    ],
  },
  {
    id: 3,
    title: "Documents to be Attached",
    description: `
    Those seeking admission in should produce the following, For Kindergarten (Nursery to K.G.-II)`,
    features: [
      "Birth Certificate issued by the competent authority",
      "Parent ID",
      " Address Proof",
      "EWS/ BPL certificate from competent authority (if applicable).",
      "Child’s Photographs (Two)",
      "Address Proof of the parents/child",
      "Income certificate of the parents",
      "Caste Certificate of the child",
    ],
  },
  // {
  //   id: 12,
  //   title: "",
  //   description: `Those seeking admission should produce the following:`,
  //   features: [
  //     "Birth Certificate",
  //     "A School Leaving Certificate from the school last attended.",
  //     "Progress Report of the child indicating academic performance and participation in co-curricular activities in the school last attended.",
  //     "Registration with CBSE, in case, admission is sought in Class X and XII on the genuine transfer of parents in addition to (2) & (3) above.",
  //     "4 Passport size photographs",
  //     "Address Proof",
  //     "Medical Certificate",
  //     "SC/ST/EWS/BPL Certificate as per the process and rules of the schools.",
  //   ],
  // },
  // {
  //   id: 13,
  //   title: "Admission Approval",
  //   description: ``,
  //   features: [
  //     "The Principal & Admission Committee together will give the final approval of admission.",
  //     "Class VI onwards, all admissions to be approved by Head ECD.",
  //   ],
  // },
];

export const curriculum = [
  {
    title: "Primary Curriculum",
    navigation: "primary",
    text: "Designed to nurture the child in all dimensions of his or her life",
    image: primary,
  },
  {
    title: "Secondary Curriculum",
    navigation: "secondary",
    text: "A skills-focused programme that provides students with a broad and balanced knowledge of key subjects",
    image: secondary,
  },
  {
    title: "Senior Secondary Curriculum",
    navigation: "sr-secondary",
    text: "Encompassing learning areas to foster competences in our students",
    image: sr_secondary,
  },
];
export const pre_primary_data = [
  {
    id: 1,
    description:
      "Pre-Primary is the foundational step of education where the learning is imparted to the age group of 3 to 6 years. The curriculum is a blend of both Experiential and Project based learning where the students have hands on experience of the learning resulting them to retain the learning lifelong. We want children to interact with each other and explore various aspects of life which includes taking care of themselves, awareness towards environment, proper physical/mental growth and development, use of mathematical concepts in day-to-day life experiences.",
    description_image: [pre1],
  },
  {
    id: 2,
    title: "",
    description:
      "The benefits of such deep learning at this stage are memory retention, life-skill development and emotional growth. Activity based and Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected together and integrated with a thematic approach making teaching and learning active and interactive. The starting ground is the uniqueness of the child, active learning, and integration in a group and a collective partnership. This way learning becomes meaningful and a child makes deeper connection with the world.",
    description_image: [pre2],
  },
];
export const primary_data = [
  {
    id: 1,
    title: "Pre-Primary Curriculum",
    description:
      "Pre-Primary is the foundational step of education where the learning is imparted to the age group of 3 to 6 years. The curriculum is a blend of both Experiential and Project based learning where the students have hands on experience of the learning resulting them to retain the learning lifelong. We want children to interact with each other and explore various aspects of life which includes taking care of themselves, awareness towards environment, proper physical/mental growth and development, use of mathematical concepts in day-to-day life experiences.",
    description_image: [pre1],
  },
  {
    id: 2,
    title: "",
    description:
      "The benefits of such deep learning at this stage are memory retention, life-skill development and emotional growth. Activity based and Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected together and integrated with a thematic approach making teaching and learning active and interactive. The starting ground is the uniqueness of the child, active learning, and integration in a group and a collective partnership. This way learning becomes meaningful and a child makes deeper connection with the world.",
    description_image: [pre2],
  },
  {
    id: 1,
    title: "Primary Curriculum",
    description:
      "The primary curriculum is a blend of both Experiential and Project-based learning where the students have hands-on experience of the learning resulting in them retaining the learning lifelong. Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected and integrated with a thematic approach making teaching and learning active and interactive.",
    description_image: [primary_curriculum],
  },
  {
    id: 2,
    title: "Interactive Classes ",
    description:
      "Interactive classes encourage students’ participation and engagement for better learning and overall development of a child. As the primary classes are the foundational years of education, Hermann Gmeiner School has well-equipped Interactive board classrooms, open play areas, activity corners, reading corners, etc. to reduce dependency on textbooks and increase focus on learning by interacting. Students do a lot of activities like making floor games to learn tables and other numeracy-related topics. The children take part in poem recitations, debates, speeches, and group discussions which make them more confident and learn from their peer group.",
    description_image: [interactive],
  },
  {
    id: 3,
    title: "  Happy Classrooms",
    description:
      "Three to seven years are crucial in everyone’s life, here learning grows through analyzing and evaluating an issue, and when it is done through activities and play the way, the method then creates happy classrooms. Learning is done including creativities and activities for the students, making them happy in the course of learning. Students do recreational activities like drawing, painting, making postcards, puppetry, and showcase their talent through ME time.",
    // description_image: [happy_classroom],
    description_image: [experimental_learning],
  },
  //   {
  //   id: 3,
  //   title: "Experimental Learning",
  //   description:
  //     "We at Hermann Gmeiner School, follow Project-based learning which includes the inculcation of Stem Curriculum, Robotics Labs, and Theme curriculum that provides deeper learning among students. The curriculum framework has a major focus on the cognitive development of the students to achieve deeper and a more scientific approach. Students do practical core learning of everything which they will retain for their lifetime. They do outdoor (playing with mud, nature walk), and indoor activities (making presentations and digital posters) which enhance their holistic learning.",
  // },
];
export const secondary_data = [
  {
    id: 1,
    description: `At this stage, students have the choice of a curriculum leading to the All India Secondary School Examination conducted by the Central Board of Secondary Education (CBSE), New Delhi. At Secondary school we offer:
`,
    features: [
      "Languages (English & Hindi)",
      "Science",
      "Mathematics",
      "Social Studies",
    ],
    features_image: [secondary_1],
  },
  {
    id: 2,
    title: "",
    description:
      "The secondary curriculum is aimed for the holistic development of students so that they develop effective communication and critical thinking skills. The syllabus is devised in a manner which ensures the students strengthen their work habits.The subjects chosen in this curriculum prepares the students for further studies.",
    // description_image: [secondary2],
  },
];
export const sr_secondary_data = [
  {
    id: 1,
    description: `The Senior Secondary School curriculum further builds on the skills and knowledge acquired by the students in the earlier classes. Life skills like social skills, thinking skills – critical, creative, decision making and problem-solving, emotional skills, and values are embedded in the curriculum. The school offers two streams at the Senior Secondary level- Commerce, and Humanities.`,
    description_image: [sr_secondary_1_1],
  },
  {
    id: 2,
    title: "",
    description: `A combination of subjects helps the students in their niche and areas they want to particularly focus on. It helps them to filter out the vast range of available opportunities. Professional guidance and counselling are very crucial at this stage. The school trains its staff according to the needs and demands of the children.
    The school offers academics with a subject combination as: `,
  },
  {
    description: "srsec",
  },
];
// export const alumni_data = [
//   {
//     id: 1,
//     title: "Nikhel Bakshi",
//     occupation: "Youngest Chief Flying Instructor & Trainer",
//     description: `Youngest Chief Flying Instructor & Trainer
//     I got the privilege of being a part of Hermann Gmeiner School, where I not only grew physically but also emotionally and psychologically. The lessons and values taught here are embedded deep into my mind and are a part of my personality. I will cherish the experiences and memories of the school and hon'ble teachers forever. From 2006 - 2016 (10 years), I have been the Youngest Chief flying Instructor/Trainer and have trained more than 300 pilots who are now flying in different airlines in India and in foreign airlines as well. From 2017 till date, I am working as a Captain in Indigo Airlines and holding a management post as well as that of a Fleet Coordinator (Delhi Base).
//     `,
//     image: nikhel,
//   },
//   {
//     id: 2,
//     title: " Ankita Lal",
//     occupation:
//       "Additional Senior Civil Judge in Saket District Courts, New Delhi.",
//     description: `Additional Senior Civil Judge in Saket District Courts, New Delhi.
//     School plays a very important part in our lives. What we are today is because of what we have learnt from our schools. I feel blessed being a part of Hermann Gmeiner School Faridabad. The school gave me an environment which helped me excel in my academic goals. The teachers were more helpful than I had ever expected. Not only did the school cater perfectly to my academic needs but also helped me build my personality and co-curricular skills.
//     `,
//     image: ankita,
//   },
//   {
//     id: 3,
//     title: "Sandeep Kak",
//     occupation: "General Manager - Otis Elevators",
//     description: `General Manager - Otis Elevators (Bangalore, Karnataka)
//     The School is a stepping stone, responsible for the overall development of a naive and innocent child into a responsible, sensible, and law-abiding citizen. It is a home away from home for all its students and for me too, Hermann Gmeiner School was my extended family for five years. The school was responsible for shaping my character and personality. At Hermann Gmeiner School, I and most of my peers have always felt that teachers were our friends, philosophers, and guides. They cared about every student & always tried to give the best advice.
//     `,
//     image: sandeep,
//   },
//   {
//     id: 4,
//     title: "Bharat Bansal",
//     occupation: "Entrepreneur in the USA",
//     description: `Entrepreneur in the USA
//     Hermann Gmeiner School has been my alma mater for a long time. I have been extremely lucky to have studied here and have made lifelong friends in this school. FIGS has given a very unique perspective to me and my batch mates, due to its unique setting & approach to education in both curriculum and life skills. I wish the school all the best and will also be available to mentor any junior students if needed.
//     `,
//     image: bharat_bansal,
//   },

//   {
//     id: 5,
//     title: "Subhadeep Sanyal",
//     occupation: "Vice President - Omnivore Partners",
//     description: `Vice President - Omnivore Partners
//     I earned a B.Sc. (Hons) in Agriculture from CCS HAU, Hisar where I was the batch topper (2005-09). I completed my Masters degree from IIM Ahmedabad, where I specialized in agribusiness during the PGDM- ABM (2009-11). The reason why I had chosen SOS Hermann Gmeiner School over other schools in Faridabad was due to the ethos which the school had and still continues to have. The open culture and sense of collaboration among students and faculty, complete sense of freedom for a child's overall growth and the need to be rooted to one's core values were probably the greatest takeaways for me from the school.
//     `,
//     image: subhadeep,
//   },

//   {
//     id: 6,
//     title: "Karan Babbar",
//     occupation: "Product Consultant - Accenture Service Private Limited ",
//     description: `Product Consultant - Accenture Service Private Limited
//     Excellent education is one where a child's interests are nurtured and built upon. Hermann Gmeiner School tries to put in all their efforts in imparting excellent education to all the students. It's not only about academics but the extracurricular activities are done in the school to ensure the overall development of a child. During my school days, I was much fascinated by computers and our teachers helped me in building interest by exposing me to different technologies. This exposure helped me in pursuing B. Tech in Computer Science.
//     `,
//     image: karan,
//   },
//   {
//     id: 7,
//     title: "Mr. Ajay Baisla",
//     occupation: "Municipal Councilor",
//     description: `Product Consultant - Accenture Service Private Limited
//     Excellent education is one where a child's interests are nurtured and built upon. Hermann Gmeiner School tries to put in all their efforts in imparting excellent education to all the students. It's not only about academics but the extracurricular activities are done in the school to ensure the overall development of a child. During my school days, I was much fascinated by computers and our teachers helped me in building interest by exposing me to different technologies. This exposure helped me in pursuing B. Tech in Computer Science.
//     `,
//     image: aa,
//   },
//   {
//     id: 8,
//     title: "Dr.Rajeev Arora",
//     occupation: "Specialised Dentist and Senior lecturer.",
//     description: `Specialised Dentist andSenior lecturer at Manav Rachna Dental College. Served as Hony. Secratary  of Indian Dental Association Faridabad in 2012  & 2013 and as President in 2015.
//     `,
//     image: a,
//   },
//   {
//     id: 9,
//     title: "Anchal Gulati",
//     occupation: "DD News Anchor",
//     image: b,
//   },
//   {
//     id: 10,
//     title: "Jeevant",
//     occupation: "Professional Photographer",
//     image: c,
//   },
//   {
//     id: 11,
//     title: "Bhushan Sachdeva",
//     occupation: "Chartered Accountant",
//     image: d,
//   },
//   {
//     id: 12,
//     title: "Devender Chaudhary",
//     occupation: "Co convener urban local body",
//     image: e,
//   },
// ];
export const admissions_data = [
  {
    id: 1,
    title: "Admission Criteria",
    description: `Admission to the school is made without any distinction of religion, race, caste, place of birth, etc. As regards reservations for SC/ST etc. students, it is governed by the Education Act read, in accordance with the rules on the subject as applicable in the state, organized before each technical examination. Admission is granted as and when vacancies are available in any class. `,
    image: false,
  },
  {
    id: 2,
    title: "",
    description: `Admission forms are available in the school office from the 15th March depending upon the vacancy in different classes. All admissions close by 31st July. Transfer cases from other states can be considered against vacancy even during the running session. For admission in class IX, the entrance exam will be of 2.5 hours in the subjects of English, Mathematics, and Science.
    `,
    image: false,
  },
  {
    id: 3,
    title: "",
    description: `For admission in a class, XI counseling will be conducted for Hermann Gmeiner School students, and other students' entrance tests will be conducted in the subject offered, subject to the availability of vacancies. Admissions to X and XII are strictly governed by CBSE norms. Admission to other classes shall purely depend on the vacancy and merit of the student. No claim for the seat can be made, whatsoever admission will be granted strictly on the merit of qualified candidates.
    `,
    image: false,
  },
  {
    id: 4,
    title: "",
    description: `Transfer cases from other states can be considered against vacancy even during the running session. For admission in class IX, the entrance exam will be of 2.5 hours in the subjects of English, Mathematics, and Science.
    For admission in a class, XI counseling will be conducted for Hermann Gmeiner School students, and other students' entrance tests will be conducted in the subject offered, subject to the availability of vacancies.`,

    image: false,
  },
  {
    id: 5,
    title: "",
    description: `Admissions to X and XII are strictly governed by CBSE norms. Admission to other classes shall purely depend on the vacancy and merit of the student. No claim for the seat can be made, whatsoever admission will be granted strictly on the merit of qualified candidates.`,
    image: false,
  },
  {
    id: 6,
    title: "",
    description: `Admission to Nursery- Students must complete 3+ years of age as of April 1, of the academic year.
    Admission to Class 1- Students must complete 5+ years of age as on April 1, of the academic year.
    Admission to LKG- 30% of the seats in class LKG is reserved for BPL card holders/ EWS (Economically weaker section).
    Admission in classes PREP to VIII will be done on a ‘first-come-first-served’ basis. The Principal's or Admission Committee’s decision will be final and binding with regard to all admissions.`,
    image: false,
  },
  {
    id: 7,
    title: "",
    description: `Those seeking admission should produce the following:`,
    image: false,
    features: [
      "Birth certificate",
      "School leaving certificate",
      "Progress card of the previous class",
      "EWS/BPL card if applicable",
      "Address Proof",
      "Character Certificate",
    ],
  },
  {
    id: 10,
    title: "Mode of Payment of Fees",
    description: `Each parent will be provided with a fee Booklet containing Challan for deposit of fees at the school counter. The fee will be paid monthly within 10 days of the beginning of the month at the UCO bank counter, opposite sector 28, Mathura Road. Parents must mention the student's name, admission number, and class with a section at the back of the cheque. In case the fees are not deposited by the due date the same can be paid at the school counter in cash only.`,
    image: false,
  },
  {
    id: 11,
    title: "",
    description: `The fee without late fee is payable till the 21st of the first month at the school fee counter. In case the cheque is dishonored, the payment of Rs 200/- shall be charged extra from the parents. The due fee will have to be paid as long as the pupil’s name is on the rolls.`,
    image: false,
  },
  {
    id: 12,
    title: "",
    description: `Only those pupils who have paid all school dues are permitted to appear for the promotion examination. Parents must clear all fees dues by 31st January to avoid any inconvenience. Admission and Annual Fees will not be refunded if a pupil is withdrawn from the school. Security deposit will be refunded only on production of the receipt copy of the Depositor’s portion of the Fee Challan.`,
    image: false,
  },
  {
    id: 13,
    title: "",
    description: `Students' admission numbers should be written in legible handwriting at the time of depositing the fees. Fees lost on account of the wrong entry of name or admission number shall be the responsibility of the parent and in such a case the student's name shall fall in the defaulter list.`,
    image: false,
  },
];
export const facilities_data = [
  {
    id: 1,
    title: "Spacious Classrooms",
    description: `The School can boast spacious, well-ventilated, and excellently furnished classrooms to make the students learning comfortable and enjoyable. Individual tables and chairs are provided to students to make them independent and self-reliant. These specially designed tables and chairs can be brought together very easily to form circles and semicircles while the lesson is in progress to have group discussions and activities.`,
    image: [spacious_classroom],
  },
  {
    id: 2,
    title: "",
    description: `To avoid carrying heavy school bags,  a special storage facility is provided for every student in the classroom. In all classrooms, display boards are provided to display creative chart works, drawings and paintings, and craftworks. The school has the best infrastructure catering for today’s learning environment – like smart interactive classrooms with ICR facilities and the use of smart board technologies for conducive learning. This facilitates comprehensive learning and makes it enjoyable and an interesting process of learning.
    `,
    image: [spacious_classroom_2],
  },
  {
    id: 3,
    title: "STEM Lab",
    description: `STEM education experiences are made available in a variety of settings by the school as a way of fostering a diverse STEM workforce. Educators are focused on improving science and mathematics instructions employing several approaches to K-12 STEM education. Students also were given opportunities to spend time with professionals in STEM fields. 
    `,
    image: [stem_lab],
  },
  {
    id: 4,
    title: "",
    description: `STEM is an abbreviation that stands for 4 closely related subjects that are Science, Technology, Engineering and Mathematics. These four theoretical and practical subjects are taught together under STEM education and starting from basics to giving precise knowledge of the subjects, professional guidelines are available at the school’s STEM lab. The students are taught by giving them real-life world problems and they are to solve these problems by theoretical and practical approach. 
    `,
    image: [stem_lab_2],
  },
  {
    id: 5,
    title: "Computer Lab",
    description: `The school has a highly sophisticated state-of-the-art computer lab. It is equipped with a computing facility with i3 computers connected through the Local Area Network as well as Wifi. The high-speed internet connectivity of 11 Mbps (24*7) to access vast intellectual resources. These facilities are an integral part of the curriculum.
    `,
    image: [computer_lab_1],
  },
  {
    id: 6,
    title: "",
    description: `Students are expected to use computers in their day-to-day study to meet the challenges of modern learning and to keep pace with global scenarios. Students are provided training on SAP, Tally ERP 9, MS Office-2016, and the Internet. The Institute website has been dynamically designed to update the latest development in the institute on a day to day basis.
    `,
    image: [computer_lab_2],
  },
  {
    id: 7,
    title: "Laboratories",
    description: `A scientific temperament is nurtured through fully equipped and suitably designed laboratories for Physics, Chemistry, and Biology. Students are encouraged to use the facilities for enhancing their innovative and scientific skills. It also provides an open environment for students to experiment and do research work. All these labs are provided with lecture/ seminar areas too. The three divisions of the science department ensure that students do real experiments.
    `,
    image: [laboratories],
  },
  {
    id: 8,
    title: "",
    description: `Our science labs are not just a rich storehouse of costly equipment and the latest gadgets on display. They are workshops of practical experiments going far beyond the limits of the prescribed syllabus. The teachers at the physics, chemistry, and biology labs challenge them with exciting projects, giving them a strenuous time clashing with known principles and existing data. This is a place where the divine curiosity of students is at work.
    `,
    image: [laboratories_2],
  },
  {
    id: 9,
    title: "Home Science Laboratory",
    description: `Home Science is a very important subject in gaining knowledge to meet the challenge of day-to-day life with confidence. The school has a well-equipped Home-Science Lab where students are acquainted with hands-on knowledge and skills. The teacher conducts different activities in the lab like cooking, stitching, embroidery, knitting, and home management. The home science students can learn Diet Planning for regular and therapeutic needs, Floor decoration, Tie & Die, Flower Arrangement, Block Printing, Samples of different Stitches, Cooking of different recipes, and Table setting.
    `,
    image: [home_science],
  },
  {
    id: 10,
    title: "",
    description: `The home science teacher demonstrates various recipes on bakery, salads, Indian, Chinese, Mexican, and Italian cuisines. The students learn the art of cooking and hone their culinary skills by using gadgets and equipment. They also make use of these lab facilities in their practice teaching, final skill-in teaching, and craft exam.
    `,
    image: [home_science_2],
  },
  {
    id: 11,
    title: "Art & Craft Room (The Heart of Creativity)",
    description: `The Art Room fosters the creative and intellectual development of the students through quality visual arts programs, facilitated by the arts faculty. The art room drives each child to be curious and original in their work while they study a variety of Art forms such as 2D, 3D, and installations by exploring mediums like acrylic, charcoal, water, dry pastels, oil pastels, pencil coloring, clay, wires, papier mache, etc.
    `,
    image: [arts],
  },
  {
    id: 12,
    title: "",
    description: `The periods in the Art Room offer each child a learning experience that teaches him or her to be a risk-taker, thinker, inquirer, and a good communicator in a safe environment. The Art teachers help the students in drawing, painting, crafts work, best out of waste, and clay modeling. Providing the students with experiences in art, craft and design enables them to reflect critically on their own and others’ work. We aim that they learn to think and act as artists, working creatively and intelligently.
    `,
    image: [arts_2],
  },
  {
    id: 13,
    title: "Library",
    description: `The serene ambiance inspires a love for reading. Information at the click of a fingertip invites young users to delve into the magical world of words and the wealth of research material available. The school has a well-equipped library.  The eclectic collection that export constitutes the Library currently includes over 10,000 books. The Library resources comprise Encyclopedias, Dictionaries on various subjects, general collections, valuable sourcebooks, critical works as well as specific skills-related books on Personality Development, Time Management, and Communication Skills. 

    `,
    image: [library],
  },
  {
    id: 14,
    title: "",
    description: `The Library subscribes to research e-journals, besides subscribing to daily newspapers, magazines, and journals. The Digital library provides access to information not just to widen the horizons of the users' knowledge but also to support a host of their learning activities that range from book learning and newspaper reading to using e-journals and reading e-books.
    `,
    image: [library_2],
  },
  {
    id: 15,
    title: "Cultural Center (Music & Dance Room)",
    description: `This is the place where young musicians start their journey to become great composers & where the children unwind and learn the aesthetics of movement. Classical Dance (Indian & Western), Modern Dance (Indian & Western), Choreography,  Direction,  Vocal  Music (Indian and Western), Instrumental Music (Indian and Western) are all interwoven into the fabric of our educational experience.`,
    image: [cultural],
  },
  {
    id: 16,
    title: "",
    description: `Dance is an ideal means of communication to express what is too deep, too fine for words. Hermann Gmeiner School’s students learn to choreograph and are conversant with various Indian and Western dance forms like Indian Classical, Jazz, Hip Hop, Salsa and these are some of the most eagerly awaited moments for self-expression and the students can be seen enjoying to the hilt.`,
    image: [cultural_2],
  },
  {
    id: 17,
    title: "",
    description: `Lessons in Indian and Western music generate rich cross-cultural exposure and sensitize the students to this fine art. The Department of Music and Dance is committed to excellence, innovation, learning in action, and entrepreneurship. Our mission is to bridge dance practice and scholarship and to foster interdisciplinary inquiry.`,
    image: [cultural_3],
  },
  {
    id: 18,
    title: "",
    description: `As a trained faculty, we bring a challenging range of perspectives to our teaching and research, and continually re-examine our curriculum to prepare both young and mature aspirants for careers in the ever-evolving field of Performing Arts.`,
    image: [cultural_4],
  },
];

export const results_data = [
  {
    title: "Toppers: X",
    description_image: [result_10, result_10_1, result_10_2, result_10_3],
    custom_class: "custom-img",
  },
  {
    title: "Toppers: XII",
    description_image: [result_12_1, result_12_2, result_12_3],
    custom_class: "custom-img",
  },
];

export const about_data = [
  {
    title: "About Us",
    description:
      "Hermann Gmeiner School, Faridabad was founded in 1986 by Shri J.N Kaul who was the then President of SOS Children’s Village of India. It is one of the oldest schools in Faridabad with a bunch of exceptionally talented students and alumni. It is a co-educational school with classes from Nursery to XII (Medical, Non-Medical, Commerce, and Humanities).",
  },
  {
    title: "",
    description:
      "The school has world class infrastructure that makes it comfortable for the students to learn and grow. The faculty leaves no stone unturned to look after the development of the students. The alumini’s experiences speak for the successful careers the students established here.",
  },
  {
    title: "Our Values",
    description:
      "Hermann Gmeiner was a man of principles and ethics. His love and compassion for children is what led him to do the social deed of education and establish SOS Children’s Village Association in 1949. Today, these institutions are active in almost 135 countries, spreading its love and knowledge.",
  },
  {
    title: "",
    description:
      "Our value and virtue is what we strive for and work our very best for all our students to learn and adapt the goodness all over. We teach our children how to excel in not only academics but every field out there and how to make a name and position for self. We teach them how to handle life’s hardships along with book and physical knowledge.",
  },
];
export const nep_data = [
  {
    id: 1,
    icon: <FaSchool />,
    description:
      "Hermann Gmeiner School pedagogy strives to make the schooling process more insightful. It has expanded the horizon of learning and made it more interesting, and motivating. The aim is to focus on the all-round development of the students rather than focusing only on the theoretical part. This makes them ready to face the outer world.",
    extra_description: ["NEP"],
  },
  {
    id: 2,
    title: "Our Curriculum aims to",
    description: "",
    features: [
      "Encourage students to think critically and solve problems through project-based learning",
      "Seamlessly integrate art into the curriculum to enhance the appreciation for aesthetic visual and performing arts.",
      "Foster holistic development through sports, i.e. Health and Physical Education.",
      "Offer activities for curricular and co-curricular enrichment via clubs and work education.",
      " Engage students in addressing real-world problems and issues important to humanity to lead change.",
      " Provide differentiated teaching-learning activities to personalize learning.",
    ],
  },
  {
    id: 3,
    title: "Key Features of the Curriculum",
    description: "",
    features: [
      "Individualized attention – low student-teacher ratio",
      "Stimulating learning environment",
      "Emphasis on all-round development and co-curricular activities",
      "Integration of ICT and Innovation in the Curriculum",
      "Parental Engagement Programmes",
      " Eco-Literacy Curriculum",
      "360-degree assessment approach",
      "Skill-based subjects Imbibing values & social skills",
    ],
  },
];
export const life_curriculum_data = [
  {
    id: 1,
    description: `Adapting the Life Skill Curriculum in the daily routine of every child at the primary stage is a very crucial part of our education. The basic hygiene importance like handwashing and daily bathing, and the value of chivalry and gratitude towards one another must be taught at their impressionable age and fortified over the years. The three components of the Life Skill Curriculum; the individual life-skill, the social skill, and the effective decision making skills are some of the eminent topics of our syllabus. To not only make our students academic geniuses, but to also refine their social and personal skills is what we aim for.`,
    description_image: [life_skill_1],
  },
  {
    id: 2,
    title: "",
    description: `We believe in testing and creating challenges for our students, so they can break their barriers and overcome their judgemental and stereotypical fears. To educate all of them about daily chores, irrespective of the gender, makes the batchmates comfortable and more open to these topics. The ten core Life Skills adopted by our education system are - 1. Self-awareness, 2. Empathy, 3. Critical Thinking, 4. Creative Thinking, 5. Decision Making, 6. Problem Solving, 7. Effective Communication, 8. Interpersonal relationship, 9. Coping with stress, 10. Coping with Emotion.
    `,
    // description_image: [life_skill_2],
  },
];
export const examination_data = [
  {
    id: 1,
    title: "Assesment",
    description: `At specific periods of time, we conduct secure and stable examinations for the students of all grades to not only test their capabilities but also to help them gain self-confidence. The children are marked on the basis of their academic and co-curricular results along with their year-long behaviour and activities.`,
  },
];
export const skill_vocational_data = [
  {
    description: [
      `Vocational subjects include more hands-on training and less of the traditional academic learning. We at Hermann Gmeiner School focus on integrating art with the curriculum for all the students to appreciate the aesthetics and visual performing arts. Practical or first-hand knowledge about the market or trade is a very essential part of the vocational training that we provide to the learners.`,
      `We always prioritize our students and for that we offer differentiated teaching-learning activities as well as personalized learning. All the varied Skill and Vocational subjects are included and taught at our institution. A team of dignified and intellectual teachers is always on the stand to help the students master the subjects.`,
      `Emulating the skill-based model of education as per NEP 2020 and CBSE guidance, we have also introduced the skill subjects at the secondary level. Choosing any one Skill subject at this level can help the child to pursue what truly interests or pleases him or her. This liberty promotes a sense of self-esteem in accepting one’s talents and strengths.`,
    ],
  },
  {
    title: "Classes VI-VIII",
    features: ["Financial Literacy", "Handicraft"],
  },
  {
    title: "Classes  IX-X",
    features: [
      "Information Technology",
      "Spoken English",
      "Personality Development",
    ],
  },
  {
    title: "Classes XI-XII",
    features: ["Web Application", "Marketing", "Data Entry", "Healthcare"],
  },
];
export const co_curricular_data = [];
export const house_clubs_data = [
  {
    id: 2,
    title: "Sports Club",
    description: `Sports club works in promoting physical and mental fitness and a healthy lifestyle among the students. The club conducts sports meets – both inter-house and inter-school competitions. It helps facilitate the process of building the concentration and confidence levels of students. Students participate in different kinds of physical activities that make them active and agile. International Yoga Day was celebrated online at school. Facebook live sessions on fitness and exercising were conducted. The game period forms an integral and important part of the school curriculum. Sports clubs also focus on increasing student participation in various sporting activities.`,
    image: [sports_club],
    icon: [sports_club_icon],
  },
  {
    id: 2,
    title: "Anti-bullying Club",
    description: `The anti-bullying club makes the children understand the nuances of bullying. It makes them realise the cause and effect that happens in the process of bullying. It conducts activities like slogan writing, poster making, nukkad natak to acquaint students with the information that helps and supports students in combating bullying.`,
    image: [antibullying],
    icon: [anti_bullying_icon],
  },

  {
    id: 3,
    title: "Environment Club",
    description: `The environment club conducts regular workshops and activities like planting trees, conducting seminars, showing children the importance of the environment and how we together need to take steps in the direction of making it better. The purpose of this club is to create awareness of environmental issues, such as protection, conservation, preservation, and restoration with an emphasis on educating and empowering students.`,
    image: [environment_club],
    icon: [environment_club_icon],
  },
  // {
  //   id: 4,
  //   title: "Health Club",
  //   description: `
  //   Health Club is spacious, airy, and well equipped with all the modern facilities for catering to an overall fitness regime for the students. The instructors facilitate the process by drafting specialised programs for the children depending on their needs. The instructors focus on the nutritional components of the health of students. They inculcate healthy eating habits and impart the importance of a healthy balanced diet for a healthy life.
  //   They conduct activities for maintaining a healthy body and mind. Yoga meditation and Asanas are done with the students for a healthy mind and physical health. Cardio, strengthening exercises, and Zumba exercises are done for making the students agile, energetic, and flexible. Several sports meets are conducted for garnering the sportsmen's spirit in the students.
  //   `,
  //   image: [health_1],
  //   icon: [health_club_icon],
  // },
  {
    id: 4,
    title: "Value Club",
    description: ` The value club works in instilling values and morals which helps students to become independent and respectful members of society. It makes the students realise the importance of value and their significance in their lives. It makes students more empathetic to the needs of others and society.`,
    image: [value_club],
    icon: [health_club_icon],
  },

  {
    id: 5,
    title: "Social Impact Club",
    description: `The Social Impact club promotes and awareness of self and community. It sensitises students to the needs of the society and develops a spirit of citizenship in them. The club organises regular activities to enhance student understanding and involvement in helping their neighbourhood communities. It empowers students with compassion, pro-activeness and resilience`,
    image: [social_impact],
    icon: [social_impact_club_icon],
  },

  // {
  //   id: 6,
  //   title: "Communication Club",
  //   description: `Communication club is a group of students that strives to improve their communication and presentation skills of the students. It helps to improve the language skills of the students and make them proficient in speaking. This is done by a series of fun and interactive games through which students learn about expressions, confidence and elocution.`,
  //   image: [budding_2],
  // },
  {
    id: 6,
    title: "Gender Sensitivity Club",
    description: `This club helps the students understand various genders and their importance in society. It helps to create awareness about various aspects of all genders and also teaches respect and equality for all. It gives them an understanding of how gender roles affect the personal and professional life of people. It helps to understand ways in which we can work in rebuilding these gender roles for a more cohesive society.`,
    image: [gender_img],
    icon: [gender],
  },
  // {
  //   id:7,
  //   title: "Expressions Club",
  //   description: `The club makes the students aware about the various Genders in society. It makes them aware about gender roles and how society plays an important role in instilling these roles on various genders. It gives them an understanding of how gender roles affect the personal and professional life of people. It helps to understand ways in which we can work in rebuilding these gender roles for a more cohesive society.`,
  //   image: [expression_img],
  //   icon: [expression],
  // },
  {
    id: 9,
    title: "House System",
    description:
      "The school has four houses. The houses are allotted to the children when they join the school. They remain in the same house till they are a part of the school. The school organizes inter-house competitions in the field of science, computer science, technology, arts, sports, music, and dance. These competitions facilitate healthy competition and generate a house spirit and loyalty among the students.",
    image: [house_system],
  },
  // {
  //   id: 13,
  //   title: "School Disaster Management Committee (SDMC)",
  //   description: `The school disaster management committee makes students aware of the various hazards and disasters. It conducts online and offline sessions where the students are shown the videos like fire safety, earthquake readiness, etc. It conducts mock drills in school so that the children get hands-on experience of dealing with such situations.
  //   `,
  //   image: [sdmc],
  // },
  // {
  //   id: 14,
  //   title: "",
  //   description: ` It also includes making students empathetic towards their classmates, making them understand the varying needs of their fellow students and people. It imparts understanding on concepts like the buddy system, children with special needs (orthopedic, visual, or learning). With this, children become compassionate and helpful towards their classmates and people in need.`,
  //   image: [sdmc_2],
  // },
];

export const houses_school = [
  {
    id: 1,
    title: "Tilak House",
    description: `It depicts Bravery which inculcates in students that they should not fear at any given situation in life and should face all the situations upfront and with utmost Bravery.`,
    color: "#0440B6D6",
    houseImg: [blue],
  },
  {
    id: 18,
    title: "Rajguru House ",
    description: `It depicts WISDOM which teaches students to be wise and kind to every being on the earth and to treat everyone equally.`,
    color: "#FFF616",
    houseImg: [yellow],
  },
  {
    id: 19,
    title: "Subhash House",
    description: `It depicts VICTORY. The values given here are that students should have a competitive spirit and should try and win all the tasks given to them. Like they should have a competitive spirit in life.`,
    color: "#FF1212",
    houseImg: [red],
  },
  {
    id: 20,
    title: "Azad House",
    description: `It depicts HONESTY which imbibes in students to do all the given duties with utmost honesty and should not deceive anybody in life.`,
    color: "#24EA03F5",
    houseImg: [green],
  },
];
export const student_council_data = [
  {
    id: 1,
    description: `We truly believe in developing leadership qualities in our students. Our Student Council is a group of elected and volunteered students who work together as a team under the guidance of our professional educators, and focus on maintaining discipline and respectful environment for all the students and faculty under the rules and regulations of the school. Student Council also represents and holds the face of student expression and assistance in school affairs and activities.
 
    `,
    description_image: [student_council_2],
  },
  {
    id: 2,
    title: "",
    description: `The school comprises the student’s council headed by the Head Girl and Head Boy along with the Vice Head Girl and Vice Head Boy. The houses are represented by the House Captains and Vice Captains. The council also has representation for the cultural, sports and discipline committees represented by students heading these particular areas. The students are guided and trained for undertaking their responsibility with sincerity and passion by the teachers and house in- charge.`,
    description_image: [student_council_1],
  },
];
export const disclosure_data_general = [
  {
    id: 1,
    title: "S. No",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "NAME OF THE SCHOOL",
    description: "Hermann Gmeiner Senior Secondary School",
  },
  {
    id: 3,
    title: "2",
    name: "AFFILIATION NO (IF APPLICABLE)",
    description: "632695",
  },
  {
    id: 4,
    title: "3",
    name: "SCHOOL CODE (IF APPLICABLE)",
    description: "23320303210",
  },
  {
    id: 5,
    title: "4",
    name: "COMPLETE ADDRESS WITH PIN CODE",
    description: "Khajurikalan Road, Piplani,Bhopal (M.P) 462021",
  },
  {
    id: 6,
    title: "5",
    name: "PRINCIPAL NAME",
    description: "Mrs Sunita Singh",
  },
  {
    id: 7,
    title: "6",
    name: "PRINCIPAL QUALIFICATION",
    description: "M.A, M.Ed, PG Diploma in Counselling & Guidance",
  },
  {
    id: 8,
    title: "7",
    name: "SCHOOL EMAIL ID",
    description: "principal.bhopal@hermann-gmeiner-edu.org",
  },
  {
    id: 9,
    title: "8",
    name: "CONTACT DETAILS (LANDLINE/MOBILE)",
    description: "0755-2756236",
  },
];
export const disclosure_data_documents = [
  {
    id: 1,
    title: "S. No",
    name: "DOCUMENTS/INFORMATION",
    description: "LINKS OF UPLOADED DOCUMENTS ON YOUR SCHOOL'S WEBSITE",
  },
  {
    id: 2,
    title: "1",
    name: "COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY",
    description: "",
    customcomponent: require("../src/pdf/affilation.pdf"),
  },
  {
    id: 3,
    title: "2",
    name: "COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE, AS APPLICABLE",
    description: "",
    customcomponent: require("../src/pdf/Trustsocietyregistration.pdf"),
  },
  {
    id: 4,
    title: "3",
    name: "COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT",
    description: "",
    customcomponent: require("../src/pdf/noc.pdf"),
  },
  {
    id: 5,
    title: "4",
    name: "COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE",
    description: "",
    customcomponent: require("../src/pdf/recognisation.pdf"),
  },
  {
    id: 6,
    title: "5",
    name: "COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE",
    description: "",
  },
  {
    id: 7,
    title: "6",
    name: "COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY",
    description: "",
  },
  {
    id: 8,
    title: "7",
    name: "COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR AFFILIATION/ UPGRADATION/ EXTENSION OF AFFILIATION OR SELF CERTIFICATION BY SCHOOL",
    description: "",
    customcomponent: require("../src/pdf/deo.pdf"),
  },
  {
    id: 9,
    title: "8",
    name: "COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES",
    description: "",
  },
];
export const disclosure_data_results = [
  {
    id: 1,
    title: "S. No",
    name: "DOCUMENTS/INFORMATION",
    description: "LINKS OF UPLOADED DOCUMENTS ON YOUR SCHOOL'S WEBSITE",
  },
  {
    id: 2,
    title: "1",
    name: "FEE STRUCTURE OF THE SCHOOL",
    description: "",
    customcomponent: require("../src/pdf/Feestructure2.pdf"),
  },
  {
    id: 3,
    title: "2",
    name: "ANNUAL ACADEMIC CALENDAR",
    description: "",
    customcomponent: require("../src/pdf/Holiday (2).pdf"),
  },
  {
    id: 4,
    title: "3",
    name: "LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)",
    description: "",
    customcomponent: require("../src/pdf/smc.pdf"),
  },
  {
    id: 5,
    title: "4",
    name: "LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS",
    description: "",
    customcomponent: require("../src/pdf/association2.pdf"),
  },
  // {
  //   id: 6,
  //   title: "5",
  //   name: "LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABILITY",
  //   description: "",
  // },
  {
    id: 7,
    title: "5",
    name: "HOLIDAY",
    description: "",
    customcomponent: require("../src/doc/Holiday List-Students-2024 (1).docx"),
  },
  {
    id: 8,
    title: "6",
    name: "RESULT",
    description: "",
    customcomponent: require("../src/doc/RESULT_HERMANN GMEINER SCHOOL, BHOPAL.xlsx"),
  }
];
export const disclosure_data_staff = [
  {
    id: 1,
    title: "S. No",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "PRINCIPAL",
    description: "Mrs Sunita Singh",
  },
  {
    id: 3,
    title: "2",
    name: "TOTAL NUMBER OF TEACHERS",
    name1: "PGT",
    name2: "TGT",
    name3: "PRT",
    description: "31",
    description1: "02",
    description2: "14",
    description3: "15",
  },
  {
    id: 4,
    title: "3",
    name: "TEACHERS SECTION RATIO",
    description: "1:30",
  },
  {
    id: 5,
    title: "4",
    name: "DETAILS OF SPECIAL EDUCATOR",
    description: "",
  },
  {
    id: 6,
    title: "5",
    name: "DETAILS OF COUNSELLOR AND WELLNESS TEACHER",
    description: "",
  },
];
export const disclosure_data_results_10 = [
  {
    id: 1,
    title: "S. No",
    extra_title: "YEAR",
    name: "NO. OF REGISTERED STUDENTS",
    description: "NO. OF STUDENTS PASSED",
    extra_description: "PASS PERCENTAGE",
  },
  {
    id: 2,
    title: "1",
    extra_title: "2022",
    name: "58",
    description: "53",
    extra_description: "90",
  },
  {
    id: 3,
    title: "2",
    extra_title: "2023",
    name: "59",
    description: "57",
    extra_description: "94",
  },
  {
    id: 4,
    title: "3",
    extra_title: "2024",
    name: "50",
    description: "50",
    extra_description: "100",
  },
];
export const disclosure_data_results_12 = [
  {
    id: 1,
    title: "S. No",
    extra_title: "YEAR",
    name: "NO. OF REGISTERED STUDENTS",
    description: "NO. OF STUDENTS PASSED",
    extra_description: "PASS PERCENTAGE",
  },
  {
    id: 2,
    title: "1",
    extra_title: "2024",
    name: "18",
    description: "18",
    extra_description: "100",
  },
];
export const disclosure_data_school = [
  {
    id: 1,
    title: "S. No",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)",
    description: "19020.2",
  },
  {
    id: 3,
    title: "2",
    name: "NO. AND SIZE OF THE CLASS ROOMS (IN SQUARE MTR)",
    description: "33.44",
  },
  {
    id: 4,
    title: "3",
    name: "NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN SQUARE MTR)",
    description: "",
    description1: "46.45",
  },
  {
    id: 5,
    title: "4",
    name: "INTERNET FACILITY",
    description: "Yes",
  },
  {
    id: 6,
    title: "5",
    name: "NO. OF GIRL'S TOILET",
    description: "7",
  },
  {
    id: 7,
    title: "6",
    name: "NO. OF BOY'S TOILET",
    description: "5",
  },
  {
    id: 8,
    title: "7",
    name: "LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL",
    description: "Nil",
  },
];
export const trip_data = [
  {
    id: 1,
    title: "Adventure Activities",
    description: `To help the students become confident, self-assured and develop some leadership skills, the school encourages them to participate in some adventure activities like, show and demonstrate Science experiments (Visited Science Centre), and visit ‘Historical Monuments’ during school trips.`,
    description_image: [trips_1],
  },
  {
    id: 2,
    title: "Educational Tours",
    description: `It provides a practical orientation to the theoretical knowledge base that the students have. It gives the students a hands-on experience of the various local Institutions, Public utilities, Community services and enhances their learning outcomes. This goes a long way in creating a sense of community sensitization among them.`,
    description_image: [trippara2],
  },
  {
    id: 3,
    title: "Trips Planned",
    description: `
    Hermann Gmeiner School Bhopal provides every student to experience and learn, who are super eager to set their foot in appealing destinations. School tours have become an important part of the extra curriculum of school. Educational School Trip with educational experts building up the best school trips for students travelling to discover every corner around their local destination and also through Inter Hermann Gmeiner School activities at different locations. Taking school trips throughout the different locations of Hermann Gmeiner School, students have incredible opportunities to equip themselves with valuable knowledge and strengthen personal skills.
    `,
    description_image: [trippara3],
  },
  {
    id: 3,
    title: "",
    description: `
    Besides obtaining educational knowledge through travelling, students also have chances to meet new friends, exchange culture with local people, and explore their fascinating ways of life. School trips are amazing occasions for students to actively participate in various interactive activities and sightseeing field trips, watching monuments of historical importance, Science Museum, Tribal Museum to explore the living style and cultures of our tribal community, science fairs conducted by different sources, and do some community help. Our school educational tours broaden students’ minds and their points of view towards the world.
    `,
    description_image: [trippara4],
  },
];
export const ncc_scouts_guides_data = [
  {
    id: 1,
    title: "NCC, Band, Scouts & Guides",
    description: `In order to inculcate discipline and leadership qualities a large number of students are being trained under the Scout and Guides/ Cubs and Bulbul for making them capable, sensitised to work for the betterment of society. To inculcate discipline and leadership qualities, a large number of students are being trained under the NCC & Scout and Guides for making them capable, sensitised to work for the betterment of society and for enhancing their lifestyle and leadership qualities.`,
  },
  {
    id: 2,
    title: "NCC",
    description:
      "To establish the quality of leadership, to enhance their grace and to give all the students the pride and confidence, we give all the interested candidates special NCC training. Tough yet specialised physical and mental training along with fun sessions help the participants to develop and relax simultaneously. Specially trained teachers and officers often take various lectures and seminars to help and guide the students.",
    description_image: [ncc_1],
  },
  {
    id: 3,
    title: "School band",
    description: `Music helps educate the whole child and improves brain function. Band provides so many important attributes for students to have a well-rounded education. From discipline, structure to social interaction and needed in the real world. It also Increases cognitive reasoning, enhances collaborative works, gives emotional outlet and offers creative thinking. Out of all the many choices available, the school band and orchestra remain very popular.`,
    description_image: [school_band_1],
  },
  {
    id: 4,
    title: "Scouts and Guides",
    description: ` 
    All eligible and interested students are given fun but important teachings of scouts and guides. It helps them grow physically, mentally, socially and intellectually. Uniforms and other requirements that we provide are a must to bring in the quality of discipline among them. All of them are expected to learn the rules and regulations by heart and implement the same in their actual lives, hence making them stronger and more confident.`,
    description_image: [scouts_1],
  },
];
export const transportation_data = [
  {
    title: "",
    description: `Scouts is a voluntarily educational movement for people, mostly teenagers, which is open to all without discrimination of gender, caste, race, or any religion. The whole idea behind Scouts is to contribute to the development of youngsters in gaining their full physical, mental, emotional, social, and intellectual ability as individual human beings, as responsible Indians, and as members of their local, national, and international communities.
    A guide is someone who leads humans towards their goals. A guide helps the students to grow mentally, physically, socially, and emotionally. They help them to make informed decisions and bring in the quality of discipline in them. The children are taught the rules and regulations which makes them more confident and sincere.`,
    description_image: [bus_1],
    custom_class: "custom-img",
  },
];
export const award_text_data = [
  {
    title: "",
    description: [
      "Hermann Gmeiner School proudly highlights its achievements and presents the best of its students, whether they are excelling academically, athletically or in service to the community. We hope to inspire more students to excel, their achievements ultimately make the school become stronger.",
      "By highlighting student achievements, we aim to eliminate self-doubt and build confidence in our students that hard work is important and is making an impact. In turn, this confidence can carry over to other facets of their young lives, including making smart choices when faced with several challenges as they grow up.",
    ],
  },

  {
    features: [
      "Global School Award for Innovation in Pedagogical Practices in 2020",
      "Best Teaching Excellence Award by International School Award held at Dubai in 2018",
      "Indian School Award in 2018",
      "National School Award 2018",
      "Global Sustainable School Award 2017 as a recognition of its efforts towards community service and environment",
    ],
  },
];
// the long list of awards such as
//stand testimony to the fact that the school never rests on its laurels.

export const awards_data = [
  {
    title: "",
    image: award_8,
  },
  {
    title: "",
    image: award_10,
  },
  {
    title: "",
    image: award_9,
  },
  {
    title: "",
    image: award_1,
  },
  {
    title: "",
    image: award_2,
  },
  {
    title: "",
    image: award_3,
  },
  {
    title: "",
    image: award_4,
  },
  {
    title: "",
    image: award_5,
  },
  {
    title: "",
    image: award_6,
  },
  {
    title: "",
    image: award_7,
  },
];
export const news_text_data = [
  {
    description:
      "Hermann Gmeiner School has been incessantly creating fervor in society and has been garnering Media coverage, getting its message across to a wider audience and establishing itself as a credible school and its activities as notable.Reputed newspapers have on several occasions, published the events and happenings at Hermann Gmeiner School along with the laurels and accolades received. We humbly acknowledge this recognition and strive to be a responsible school and be dedicated towards the community welfare.",
  },
];
export const news_data = [
  {
    title: "",
    image: media,
  },
  {
    title: "",
    image: media1,
  },
  {
    title: "",
    image: media2,
  },
  {
    title: "",
    image: media3,
  },
  {
    title: "",
    image: media4,
  },
  {
    title: "",
    image: media5,
  },
  {
    title: "",
    image: media6,
  },
  {
    title: "",
    image: media7,
  },
  {
    title: "",
    image: media8,
  },
  {
    title: "",
    image: media9,
  },
  {
    title: "",
    image: media10,
  },
  {
    title: "",
    image: media11,
  },
  {
    title: "",
    image: media12,
  },
];
export const alumni_text_data = [
  {
    id: 1,
    title: "Amit Kumar Verma",
    occupation: "PCSJ (Judge)",
    description: `I genuinely accept the contribution of my school-Hermann Gmeiner School, Varanasi teachers who have been the backbone in shaping my career. Today I am selected as Assistant Prosecution Officer and PCSJ (Judge) in Uttar Pradesh, a very successful achievement for a law student. Along with academics, the school has given me the opportunity for extra co-curricular activities that helped me a lot to achieve my goal. I would ask you all to cherish the bond with your teachers. They are the ones who have educated us & have put in the efforts to bring us up in this society.
     We should show our respect & gratitude towards our teachers, they have empowered us with education; they have nurtured us with their love & affection just like our parents. Our respect makes them feel content; Teachers have always been special & will continue to do so in all times to come. First of all, we should become good human beings with the spirit of determination, dedication & application, and do hard work with honesty; you all will undoubtedly achieve the best position in life.
    `,
    image: rohan,
  },
  {
    id: 2,
    title: "Kislay Raj Srivastva",
    occupation: "Working At Sydicate Bank",
    description: `School is the temple of learning and gets trained for professional and social life. These words come in my mind when I think about my school.Whatever I am today is just only because of my school and teachers who are founder of my life, who taught me not only what written in text books but as what written in practical life. It was a relationship which I can never forget because it was not only a relationship of teacher and student but a relationship of teacher and his/her own kid.
    I will never forget my teachers like Manjula Madam, Giri Sir, Das Sir, Nirmal Rathaur Madam, Vinod Dhar Sir, Rajkumari Madam, Ramesh Sir, Pawan Sir and Rakesh Sir for their guidance and inspiration It was my faith in them and their faith in me because of which today I am successful in my life can proudly say that I was of Hermann Gmeiner School.
    `,
    image: karandev,
  },
  {
    id: 3,
    title: "Ajeet Kumar Singh",
    occupation: "Social Activist",
    description: `Hermann Gmeiner School, Varanasi schooling period from 1990 to 2003 was full of extracurricular activities ranging from speech, debates, drama, and social awakening campaigns. The basics of the schooling system were so sound that they helped me in fighting all odds with very much optimism. I wrote the civil services mains exam three times but failed to convert it into a final success. Strong basics helped me in shifting from frustration and despair towards newer hope and optimism. For creating public awareness and enlightenment I started a YouTube channel and Facebook page #voice4newIndia2022.I express my gratitude towards Hermann Gmeiner School school and all the teachers there.`,
    image: anjan,
  },
  {
    id: 4,
    title: "Vinay Pandey",
    occupation:
      "Head Of Department (Physical Education) “Sacred Heart Senior Sec Sch” (Sitapur)",
    description: `I am Vinay Pandey. I am a Pass out+2 from Hermann Gmeiner School, Varanasi in 1997. Presently I work as HOD of the Physical Education Department in “SACRED HEART SENIOR SEC SCH" (SITAPUR). When I was studying at Hermann Gmeiner School, Varanasi I was very naughty. The love and care given by my teachers and faculty made me what I am today. I feel happy to be a part of the teaching community which plays a vital role in moulding the lives of young children. I always feel this school is a special part of my life. I truly relish every moment of my life in Hermann Gmeiner School Varanasi. I am very thankful to my school teachers & parents for their support and guidance.`,
    image: prakriti,
  },

  {
    id: 5,
    title: "Rabindra Kumar Pandey",
    occupation: "Metropolitan Magistrate, Delhi",
    description: `I am pleased to state that I was a student at Hermann Gmeiner School, Varanasi where I got lots of inspiration, motivation, and encouragement to utilise my potential. The Annual function, republic day, Independence Day celebrations, and morning prayers are associated with my memories which I shall hardly forget. I did L.L.B from B.H.U. and right now I am posted as Metropolitan Magistrate, Delhi.`,
    image: abhishek,
  },
  {
    id: 5,
    title: "Shubham Singh",
    occupation: "Assistant Manager - Broadcast operation",
    description: `It was a great experience studying at Hermann Gmeiner School with a unique infrastructure and environment-friendly campus. I must say with the best teachers in the school I have learned the lesson of life. We learn things and also get many opportunities in extracurricular activities which help us to build ourselves and shape our future in a better way. My life in school was the golden period of my education.`,
    image: subham,
  },
  {
    id: 5,
    title: "Vivek Kr. Pandey",
    occupation: "ATS Infrastructure Limited –Manager –Corporate Communications",
    description: `It was a great experience studying here in this school with a unique infrastructure and environment-friendly place. I must say with the best teachers' I have ever got in my life. We learn things and also get many opportunities in extracurricular activities which help us to build ourselves and shape our future in a better way.`,
    image: vivek,
  },
  {
    id: 5,
    title: "Namrata Jaiswal",
    occupation: "Oracle India Pvt Ltd - Software Developer L2",
    description: `It was a great experience studying here in this school with a unique infrastructure and environment-friendly place. I must say with the best teachers' I have ever got in my life. We learn things and also get many opportunities in extracurricular activities which help us to build ourselves and shape our future in a better way.`,
    image: namrata,
  },
  {
    id: 5,
    title: "Lt Cdr Rakesh Kumar Pandey",
    occupation: "Instructor At Indian Naval Academy, Payyanur, Kerala",
    description: `Words Cannot define my feeling toward my alma mater after 15 years. It’s indeed exhilarating. It was a pleasure to have revisited the old memories, meeting the teachers, and the heartfelt welcome by the Principal surely brought back the school days and rejuvenated the old self.`,
    image: rakesh,
  },
  {
    id: 5,
    title: "Shwetank Pandey",
    occupation: "Assistant HOD (Indian Institution of Gems & Jewellery)",
    description: `I had a very good experience in this school from the very beginning of my school days. All teachers were very supportive as they gave me good guidance throughout. I owe a lot to my teachers for what I am today. The school days were the best part of my life, I wish I had them again. I always wish this Institution would add more feathers to its cap.`,
    image: shwetank,
  },
  {
    id: 5,
    title: "Juli Kumari",
    occupation: "Project Assistant at (CSIR)",
    description: `Overwhelmed to see my school after so many years. The love, care, and affection I got from my teacher are unforgettable. I am blessed to be a part of the school.`,
    image: juli,
  },
  {
    id: 5,
    title: "Niketa Yadav",
    occupation: "Asst. Manager – Union Bank of India",
    description: `Passed class 12 in 2005, studied in SOS Hermann Gmeiner School from 1999. Class 12 percentage: 80. It has been 13 years to leave SOS Hermann Gmeiner School, but the relation and attachment to this school and its teachers/staff is growing stronger with time. Whatever we are today is just because of the lessons and love given by our school. We have got the best friends of our life here. If I compare Hermann Gmeiner School with other school, no school can give this much love to its student, I am thankful to all teachers for making me a good citizen, a good person. The discipline and the quality of education given by our school teachers have always helped us to stand with all difficulties in life and to achieve our goals. I am currently working as Asst Manager- Union Bank of India at Lucknow.`,
    image: niketa,
  },
];
export const sports_data = [
  {
    id: 1,
    title: "Sports",
    description: `Having an active lifestyle is key to living a healthy and fit life. To ensure that our students are not only just engrossed in studies, to promote a spirit of sportsmanship and camaraderie, we encourage the students to participate in regularly held intra and inter school events within and outside of the Hermann Gmeiner School community. Taekwondo, Football, Badminton, Lawn Tennis, Table Tennis, Cricket, Chess and Yoga`,
  },
];

export const faculty_data = [
  {
    title: "Faculty & Staff",
    description: `Hermann Gmeiner School Varanasi has a very experienced, knowledgeable, 
    well-qualified and dedicated Staff, so much so that they are conscious of their 
    responsibility of being the most important block in the foundation of the Nation 
    as well as the Society.`,
    description_image: [faculty_1, faculty_2],

    custom_class: ["faculty-img"],
    extra_description: [
      `Their self-discipline is an example in itself, which always drives them to work as 
    a well-knit team to make all possible endeavour together for the holistic growth 
    of students as well the institution. They are enthusiastic, dedicated, 
    conscientious and highly motivated to adapt to new innovative ideas and well 
    versed with modern education systems.`,
      `The valuable contribution of all the Faculty members is well manifested in the 
    spectacular achievements of our students in the scholastic as well as co-
    scholastic discipline.`,
      `The Teachers grouped under various subject-wise Faculties are put under the 
    guidance of the most experienced Faculty heads. All of them always put their 
    heads together to devise improvised methodology to deliver their best to 
    facilitate the young evolving minds. This significantly contributes in promoting 
    the problem solving skills of students to enable them to grow as useful citizens.`,
    ],
  },
];

export const events_data = [
  {
    title: "Annual Day Celebrations",
    description: `Our Annual day celebration is one of the most fun and cheerful times of the year. Everyone in the school premises leave their worries behind and just blend in as one to celebrate this prestigious day of school and try their best to make it the most memorable every year.
     Annual function celebrated every year in the month of December, sports activities and alternatively cultural activities allows students to showcase their talents, overcome stage fright, gain confidence and learn to collaborate. Dignitaries from different fields are invited and Parents are also invited. Teacher’s play a very major role in the annual function as the whole function is managed by the teachers to encourage maximum participation of students.
    `,
    description_image: [annual_1],
  },
  {
    title: "Inter Hermann Gmeiner School literary competition",
    description: `The school encourages students to participate in various opportunities to express hem selves by taking parts in inter Hermann Gmeiner School literary competition. It helps to express themselves, to develop their communication skills , ethical and other moral values.`,
    description_image: [interliteracy],
  },
  {
    title: "Inter Hermann Gmeiner School sports comp",
    description: `Sports activities promotes physical and mental health and teaches discipline and cooperation. It helps to facilitate the process of concentration building and boost confidence level of students.`,
    description_image: [intersport],
  },
  {
    title: `Safety drill `,
    description: `Safety drill organized time to time to train students ,how to respond during miss-happening.`,
    description_image: [safety],
  },
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  {
    title: "Earth day celebration",
    description: `Earth day is conducted to sensitize students to care for earth which is home of all living beings.`,
    description_image: [earth],
  },
  {
    title: "News paper reading",
    description:
      "Newspaper reading activity conducted to enhance reading speed and improve pronunciation.",
    description_image: [news],
  },
  {
    title: `Poster making competition`,
    description: `It provides an opportunity to present their skills and talent in arts. `,
    description_image: [poster],
  },
  {
    title: "Hindi and English declamation",
    description: `it boosts up the confidence of students to face stage, and speak in public.`,
    description_image: [hindi],
  },
  {
    title: "Idol making competition",
    description: ` It enhances the creativity of the students , enables them to use colours  impressively.`,
    description_image: [idol],
  },
  {
    title: `Rakhi making activity`,
    description: ` It creates good bond of love and care among siblings.`,
    description_image: [rakhi],
  },
  {
    title: "Dusshera celebration",
    description: `It conveys important message to students that truth always have victory over evils.`,
    description_image: [dussehra],
  },
  {
    title: `Grand parents day`,
    description: `it develops feeling and emotions of love, care and respect for their elders.`,
    description_image: [grandparent],
  },
  {
    title: `Christmas celebration`,
    description: `It inculcates and promotes charity and kindness for all living beings.`,
    description_image: [christmas],
  },
  {
    title: "Gandhi Jayanti celebration",
    description:
      " it teaches simplicity in life and his teachings inspire students to adopt his values.",
    description_image: [gandhi],
  },
  {
    title: `Story telling activity`,
    description: `it develops to string their imagination and use their talent to express it in words.`,
    description_image: [storytelling],
  },
  {
    title: "Skit competition",
    description: `It improves communication skills, dialogue delivery  and create awareness to eradicate social evils.`,
    description_image: [skit],
  },
  {
    title: `PTM`,
    description: ` PTM conducted time to time to communicate academic and behavioral performance.`,
    description_image: [ptm],
  },
  {
    title: `Open house`,
    description: `Open house organized to communicate important messages`,
    description_image: [openhouse],
  },
  // {
  //   title: "",
  //   description: `Through a well-organized cultural evening comprising Dances, Songs, Speeches, and Poetry, an atmosphere of love and affection was created. Class XI students presented beautiful attractive gifts to their seniors. The presence of the esteemed Principal of the school, Mr. Ramesh Dhar, and reverent HMs, Ms. Vandana Tomar and Ms. Manjeet Kaur lent an additional charm to the occasion. They showered their blessings upon the students and also motivated the students to develop a tendency of hard work, perseverance, and dedication towards work.`,
  //   description_image: [farewell_2],
  // },
  // {
  //   title: "Mock Fire Evacuation Drill",
  //   description: `Hermann Gmeiner School Faridabad believes it's the responsibility of the school to ensure the safety of its students and staff, and prepare them for any unforeseen emergency. With the kind help of Mr. Ashok (Fire Safety Expert), a mock fire and evacuation drill was conducted at Hermann Gmeiner School today on 26th April 2022. Following an alarm, teachers and other school staff swung into action and performed the duties allotted to them. Students were educated about the safety and precautionary measures to be taken at the time of the fire and other natural disasters. Students were also given tips on fire safety. A demonstration of using fire safety equipment was also conducted by the experts.`,
  //   description_image: [fire],
  // },
  // {
  //   title: `Earth Day`,
  //   description: `"The earth is what we all have in common.” – Wendell Berry .The students of Grade 3 to 5 did an activity to show their love and concern for Mother Earth and its well-being. The students of Grade 3 made bags out of paper and Grade 5 made cloth bags. The children not only learned the importance of our precious environment but also how to save it.`,
  //   description_image: [environment],
  // },
  // {
  //   title: "Kids Fashion Fiesta",
  //   description: `Hermann Gmeiner School Faridabad organized Kids Fashion Fiesta for the early years where distinct topics were given to the little ones according to their age.
  //   It was a successful event full of creativity and involvement of the parents too. Kids enjoyed being dressed up and everyone was happy for being part of the program.
  //   The program was graced by our Respected Head of Education and Child Development Ms. Debaroti Bose and the students were excited by her presence. The event was concluded by our Respected Headmistress Ms. Manjeet Kaur on a happy and positive note.`,
  //   description_image: [fashion],
  // },
  // {
  //   title: `Book Donation Drive`,
  //   description: `We Rise by Lifting Others!
  //   Compassion and community welfare are the essences of any good human being; hence an initiative was taken by our Social Impact Club to inculcate these virtues amongst our learners.
  //  The school established a Book Bank facility for the underprivileged children of Gurukul of SOS FSP, Anangpur. Students of Grade IX initiated the project by sorting and packing the numerous books received through the Book Donation Drive organized in the school. They were donated to the children of Baal Panchayat, wherein children do not have the means to buy them. Our children also guided them on how to run the Book Bank. The effort resulted in a fruitful outcome, as children passed the gift of education in their own selfless way.`,
  //   description_image: [book],
  // },
  // {
  //   title: `Mothers Day`,
  //   description: `Motherhood is the exquisite inconvenience of being another person's everything.
  //   “When you are looking at your mother, you are looking at the purest love you will ever know.”
  //  There is one extraordinary relationship in this life that is truly priceless in terms of love, dedication, and devotion and that is of mother and child. The bond between a mother and her child is something that is cherished for eternity and one day is surely not enough to celebrate motherhood. However, every year, a day is specially marked for mothers all across the globe to pay homage to their selfless and unconditional love. The Mothers’ Day activities conducted for Grades VI & IX at Hermann Gmeiner School witnessed the creativity of the rising stars who prepared beautiful tiaras or bracelets, flowers, cards, photo frames for their mothers.`,
  //   description_image: [mother],
  // },
  // {
  //   title: `Covid Vaccination Drive`,
  //   description: `To contain the swell of Corona Variants by protecting students from this lethal virus, a vaccination drive was organized at Hermann Gmeiner School Faridabad in association with Civil Surgeon, Health Department, Haryana on 19th May 2022 for the students of the age bracket 12 years to 15 years (Classes VI to X) at its campus. This drive followed all the norms laid by WHO, the Ministry of Health, Government of India pertaining to Covid 19.
  //   Mr. Ramesh Dhar, the Principal, asserted that students’ safety was the prime concern for the school. The initiative of the school ensured safe vaccination for the students and allowed children in the vicinity to get benefitted.
  //   `,
  //   description_image: [covid],
  // },
  // {
  //   title: `Orientation Day Programme`,
  //   description: `“A parent is a teacher at home and a teacher is a parent in school and the child is the center of our universe.” An Orientation Programme was organised for the parents of new admission at Hermann Gmeiner School, Faridabad on 21st May 2022. The aim was to familiarise the parents, “as partners in progress” with the curriculum, rules, and regulations of the school, teaching methodologies, and the co-scholastic activities. The programme commenced with a warm welcome to the parents followed by the ceremonial lighting of the lamp. The School Principal, Mr. Ramesh Dhar, addressed the gathering. In his speech, he laid stress on effective communication between the teachers & the parents and also sought their support and suggestions so that the children could be provided the best for their overall development. He also gave guidelines about effective parenting. `,
  //   description_image: [orientation_1],
  // },
  // {
  //   title: "",
  //   description: `The parents were briefed about the curriculum, and teaching methodologies along with new initiatives of the school by the headmistress senior wing, Ms. Vandana Tomar. Later, Ms. Anuradha Rai Shukla, Examination Incharge presented information about the examination and promotion policies of the school. Not to forget the co-scholastic activities, briefed by Ms. Nidhi Sharma, Activity Coordinator, which assured them that the curriculum involves self-directed, hands-on learning activities which not only give vent to their creative talents and energies but also help in the all-round development of the child. `,
  //   description_image: [orientation_2],
  // },
  // {
  //   title: "",
  //   description: `She also explained about the school app. It was followed by a session taken by HM Junior Wing, Ms. Manjeet Kaur, who guided the parents about various principles, curriculum, and activities involved in the teaching in primary and pre-primary classes. The parents witnessed and enjoyed the dance performance by Primary children and a mashup by middle wing girls. The orientation program ended with an interactive session with the parents and a vote of thanks by HM Maam.`,
  //   description_image: [orientation_3],
  // },
  // {
  //   title: "",
  //   description: `The orientation program proved to be a sincere effort made by the school in educating the parents to look after and properly guide their children. It also helped in evolving a common teaching strategy and helped the parents in identifying suitable follow-up methods to reinforce the learning process of their children.Overall it was a fruitful meeting and the parents were in high spirits after attending the orientation programme.`,
  //   description_image: [orientation_4],
  // },
  // {
  //   title: `First Aid Kit Making Activity`,
  //   description: `From a minor ailment to a more serious injury, a "First Aid Kit" can help reduce the risk of infection. Keeping this in mind, our SDMC First Aid Team organised a First Aid Kit Making Activity for the young students of Grade III to VIII on 21st May 2022. Those children were allowed to prepare a First Aid Kit in the class. The kit included cotton swabs, an antiseptic, band-aids, pain relief ointment, etc. The children learned how these medical items can be used to treat minor cuts, burns, and scrapes, and that a first aid kit should always be kept clean and organised. This activity helped the children to learn basic first aid skills and how to make the first aid box that can enable them to care for themselves in any kind of emergency.`,
  //   description_image: [first_aid],
  // },
  // {
  //   title: `Road Safety Awareness Programme`,
  //   description: `The "Road Safety Awareness Programme" was held at Hermann Gmeiner School Varanasi on May 21, 2022, as per the directions of the Government of Uttar Pradesh. On the topic of road safety, the Senior Wing students showed a variety of profound and thought-provoking posters, banners, and chart papers. Class X-C students Arpita and Priyanshi discussed road safety. Mr DN Rajput, the Headmaster, presented some crucial road safety guidelines. Dr. Ambika Prasad Gaur, the principal, addressed the pupils and discussed a number of important actions that may be performed to reduce road accidents.
  //   The kids also planned a protest to raise awareness. Mr Arun Kumar Mishra, the Activity In-Charge, devised and coordinated the programme.`,
  //   description_image: [road_safety],
  // },
  // {
  //   title: `Watermelon Day`,
  //   description: `On 18 May 2022, the children of Hermann Gmeiner School Varanasi eagerly celebrated "Watermelon Day" in the school. Watermelon is a sweet and refreshing fruit that is ideal for a hot summer day. Watermelon has a number of symbolic implications in addition to reminding us of all the things we love about summer. Watermelon is seen as a sign of fertility in many civilizations. Watermelon may represent love, passion, and genuine love in dreams.`,
  //   description_image: [watermelon],
  // },
  // {
  //   title: `Awareness Session on Cervical Cancer`,
  //   description: `An awareness session about Cervical Cancer was held at Hermann Gmeiner School Varanasi on May 9, 2022, in which all of the school's female students aged 11 to 14 years old took part. They were well educated on the prevention and treatment of cervical cancer. All of the females were motivated to be vaccinated against cervical cancer. Mrs. Shashibala and Mrs. Rupali led the event, which was overseen by Dr. Ambika Prasad Gaur, the Principal. The programme attracted more than 150 female students.`,
  //   description_image: [awareness],
  // },
  // {
  //   title: `Pariksha Pe Charcha`,
  //   description: `PM Narendra Modi interacted with students in the Assembly Hall of Hermann Gmeiner School Varanasi on 2nd April 2022, as a programme on 'Pariksha Pe Charcha' was shown through online mode to students in classes 9 to 12 in the Assembly Hall of Hermann Gmeiner School Varanasi. PM Modi posted video suggestions for students and parents on his YouTube page, covering topics, such as day-to-day living and examinations for pupils. Memory power, the importance of technology in students' lives, depression, the best use of leisure time, attention, reaching objectives, choosing the proper vocation, time management, self-confidence, and many other themes were discussed in these movies. After gaining valuable test experience, all of the pupils felt enhanced.`,
  //   description_image: [charcha],
  // },
  // {
  //   title: `Remembrance of BR Ambedkar`,
  //   description: `On April 13, 2022, the students of Hermann Gmeiner School Varanasi put on a brief but lovely show to commemorate the 132nd birth anniversary of Dr. Bhim Rao Ambedkar, the major architect of India's Constitution. The event began with a prayer song. Dr. BR Ambedkar was honoured with flower tributes by the Principal and students. Some students spoke on Dr. Ambedkar and the Indian Constitution. Along with that, other pupils recalled the horrific episode at JalianwalaBagh in 1919, when people gathered there on Baisakhi Day were cold-bloodedly murdered.
  //   This effort of our students was probably the simplest and honest way of describing what happened at Jallianwala Bagh in Amritsar, Punjab on April 13, 1919. The Principal, Dr. Ambika Prasad Gaur addressed the children and told them about the Jalliawala Bagh incident in detail. The programme concluded with observing silence for two minutes in remembrance of the sacrifice of the people in that incident. The programme was orchestrated by Mr. Arun Mishra.
  //   `,

  //   description_image: [brambedkar],
  // },
];
